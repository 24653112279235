import { Box, Center, Divider, Stack, Text, Transition, UnstyledButton, useMantineTheme } from '@mantine/core'
import { Routing } from 'config/enums/routings'
import { ROUTING_ICONS } from 'config/icons'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import './styles.css'

const getRoutingItems = (t: TFunction) => [
  {
    icon: ROUTING_ICONS.bicycle,
    label: t('general.routing.bike'),
    value: Routing.bicycle,
  },
  {
    icon: ROUTING_ICONS.auto,
    label: t('general.routing.car'),
    value: Routing.auto,
  },
  {
    icon: ROUTING_ICONS.pedestrian,
    label: t('general.routing.walk'),
    value: Routing.pedestrian,
  },
  {
    icon: ROUTING_ICONS.none,
    label: t('general.routing.none'),
    value: Routing.none,
  },
]

const scaleTopLeft = {
  in: { opacity: 1, transform: 'scale(1)' },
  out: { opacity: 0, transform: 'scale(0)' },
  common: { transformOrigin: 'top left' },
  transitionProperty: 'transform, opacity',
}

export const MainMenu = ({
  bicycleIcon,
  position,
  /* The value prop should be used only to set a visual cue if the sub menu is open */
  value: focusedItemValue,
  visible = false,
  onItemClick,
  onItemMouseEnter,
}: {
  bicycleIcon?: JSX.Element
  position: { left: number; top: number }
  value?: Routing
  visible: boolean
  onItemClick: (value: Routing) => void
  onItemMouseEnter: (value: Routing) => void
}) => {
  const { t } = useTranslation()
  const theme = useMantineTheme()

  const hoverBackgroundColor = theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[1]

  return (
    <Box
      className="trkbk-context-menu main-menu"
      sx={{
        ...position,
        backgroundColor: theme.colorScheme === 'light' ? '#fff' : '#1A1B1E',
        border: theme.colorScheme === 'light' ? '1px solid #ced4da' : 'none',
      }}
    >
      <Transition mounted={visible} transition={scaleTopLeft} duration={150} timingFunction="ease">
        {(styles) => (
          <div style={styles} onContextMenu={(e) => e.preventDefault()}>
            <Text size="xs" px="xs" py={4} color="dimmed">
              {t('route_to_point_context_menu.title')}
            </Text>
            <Divider />
            <Stack spacing={0} py={4}>
              {getRoutingItems(t).map(({ icon, label, value }) => (
                <UnstyledButton
                  key={value}
                  className="trkbk-context-menu main-menu-item"
                  px="xs"
                  sx={{
                    backgroundColor: focusedItemValue === value ? hoverBackgroundColor : 'inherit',
                    '&:hover': {
                      backgroundColor: hoverBackgroundColor,
                    },
                  }}
                  onClick={() => onItemClick(value)}
                  onMouseEnter={() => onItemMouseEnter(value)}
                >
                  <Center
                    className="icon"
                    sx={{
                      color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7],
                    }}
                  >
                    {bicycleIcon && value === Routing.bicycle ? bicycleIcon : icon}
                  </Center>
                  <Text
                    className="text"
                    py={8}
                    style={{ color: theme.colorScheme === 'light' ? '#495057' : 'inherit' }}
                  >
                    {label}
                  </Text>
                </UnstyledButton>
              ))}
            </Stack>
          </div>
        )}
      </Transition>
    </Box>
  )
}
