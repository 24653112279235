export const StreetLegendBody = () => (
  <tbody>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/motorway.gif" />
      </th>
      <td>{'Motorway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/trunk.gif" />
      </th>
      <td>{'Trunk road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/primary.gif" />
      </th>
      <td>{'Primary road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/secondary.gif" />
      </th>
      <td>{'Secondary road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/tertiary.gif" />
      </th>
      <td>{'Tertiary road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/residential.gif" />
      </th>
      <td>{'Residential road, unclassified road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/living_street.gif" />
      </th>
      <td>{'Living street'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/service.gif" />
      </th>
      <td>{'Service'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/pedestrian.gif" />
      </th>
      <td>{'Pedestrian street'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/raceway.gif" />
      </th>
      <td>{'Raceway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/road.gif" />
      </th>
      <td>{'Road with unknown classification'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/oneway.gif" width="70" />
      </th>
      <td>{'Oneway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/footway.gif" width="70" />
      </th>
      <td>{'Footway, path'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/cycleway.gif" width="70" />
      </th>
      <td>{'Cycleway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bridleway.gif" width="70" />
      </th>
      <td>{'Bridleway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/track_nograde.gif" width="70" />
      </th>
      <td>{'Track (without grade)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/track_grade1.gif" width="70" />
      </th>
      <td>{'Track (grade 1)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/track_grade2.gif" width="70" />
      </th>
      <td>{'Track (grade 2)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/track_grade3.gif" width="70" />
      </th>
      <td>{'Track (grade 3)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/track_grade4.gif" width="70" />
      </th>
      <td>{'Track (grade 4)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/track_grade5.gif" width="70" />
      </th>
      <td>{'Track (grade 5)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/steps.gif" width="70" />
      </th>
      <td>{'Steps'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/recreation_track.gif" width="70" />
      </th>
      <td>{'Recreation track'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bridge_casing.gif" />
      </th>
      <td>{'Black casing = bridge'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/tunnel_casing.gif" />
      </th>
      <td>{'Dashed casing = tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/runway.gif" />
      </th>
      <td>{'Runway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/taxiway.gif" />
      </th>
      <td>{'Taxiway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/railway.gif" width="70" />
      </th>
      <td>{'Railway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/railway_tunnel.gif" width="70" />
      </th>
      <td>{'Railway with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/railway_disused.gif" width="70" />
      </th>
      <td>{'Disused railway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/railway_service.gif" width="70" />
      </th>
      <td>{'Siding, spur, yard, crossover'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/railway_funicular.gif" width="70" />
      </th>
      <td>{'Funicular, narrow gauge, light rail, tram'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/railway_subway.gif" width="70" />
      </th>
      <td>{'Subway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/railway_monorail.gif" width="70" />
      </th>
      <td>{'Monorail'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/railway_preserved.gif" width="70" />
      </th>
      <td>{'Preserved railway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/railway_miniature.gif" width="70" />
      </th>
      <td>{'Miniature railway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/aerialway.gif" width="70" />
      </th>
      <td>{'Chairlift, drag lift, t-bar lift, j-bar lift, platter lift, rope tow, zipline'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/gondola.gif" width="70" />
      </th>
      <td>{'Cable car, gondola lift, mixed lift'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/goods.gif" width="70" />
      </th>
      <td>{'Goods cable lift'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/ferry_route.gif" width="70" />
      </th>
      <td>{'Ferry route'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/platform.gif" width="70" />
      </th>
      <td>{'Platform'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/boundary_country_low.gif" />
      </th>
      <td>{'Country boundary'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/boundary_regional_low.gif" />
      </th>
      <td>{'Regional boundary (lower and higher zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/boundary_regional_middle.gif" />
      </th>
      <td>{'Regional boundary (middle zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/boundary_aboriginal.gif" width="63" height="33" />
      </th>
      <td>{'Reservation boundary (native peoples)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/protected_area.gif" />
      </th>
      <td>{'National park, protected area, nature reserve boundary'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/military.gif" width="61" height="31" />
      </th>
      <td>{'Military, danger area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/prison.gif" width="61" height="31" />
      </th>
      <td>{'Prison area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/zoo.gif" />
      </th>
      <td>{'Zoo, theme park boundary'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/water.gif" />
      </th>
      <td>{'Ocean, sea, lake'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/reef.gif" width="60" height="30" />
      </th>
      <td>{'Reef'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/glacier.gif" />
      </th>
      <td>{'Glacier'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/intermittent.gif" width="60" height="30" />
      </th>
      <td>{'Intermittent lake'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/forest.gif" />
      </th>
      <td>{'Forest'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/broadleaved.gif" width="60" height="30" />
      </th>
      <td>{'Broadleaved forest'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/coniferous.gif" width="60" height="30" />
      </th>
      <td>{'Needleleaved forest'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/mixed.gif" width="60" height="30" />
      </th>
      <td>{'Mixed forest'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/leafless.gif" width="60" height="30" />
      </th>
      <td>{'Leafless forest'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/unknown.gif" width="60" height="30" />
      </th>
      <td>{'Unknown forest'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bare_rock.gif" width="60" height="30" />
      </th>
      <td>{'Bare rock'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/scree.gif" width="60" height="30" />
      </th>
      <td>{'Scree, shingle'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/scrub.gif" width="60" height="30" />
      </th>
      <td>{'Scrub'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/heath.gif" />
      </th>
      <td>{'Heath'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/wetland.gif" />
      </th>
      <td>{'Wetland'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/wetland_marsh_z12.gif" />
      </th>
      <td>{'Marsh, wet meadow, saltmarsh, reed bed, fen (lower zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/wetland_bog_z12.gif" />
      </th>
      <td>{'Bog, string bog (lower zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/wetland_mangrove_z12.gif" />
      </th>
      <td>{'Mangrove (lower zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/wetland_swamp_z12.gif" />
      </th>
      <td>{'Swamp (lower zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/wetland_marsh.gif" />
      </th>
      <td>{'Marsh, wet meadow'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/wetland_saltmarsh.gif" />
      </th>
      <td>{'Saltmarsh'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/wetland_reedbed.gif" />
      </th>
      <td>{'Reed bed'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/wetland_fen.gif" />
      </th>
      <td>{'Fen'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/wetland_bog.gif" />
      </th>
      <td>{'Bog, string bog'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/wetland_mangrove.gif" />
      </th>
      <td>{'Mangrove'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/wetland_swamp.gif" />
      </th>
      <td>{'Swamp'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/wetland_tidalflat_lower.gif" />
      </th>
      <td>{'Tidal flat (lower zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/wetland_tidalflat.gif" />
      </th>
      <td>{'Tidal flat (higher zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/wetland_saltern.gif" width="60" height="30" />
      </th>
      <td>{'Salt pond'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/sand.gif" />
      </th>
      <td>{'Sand'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/beach.gif" />
      </th>
      <td>{'Beach'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/grass.gif" />
      </th>
      <td>{'Grass, grassland, meadow, village green'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/vineyard.gif" width="60" height="30" />
      </th>
      <td>{'Vineyard'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/orchard.gif" width="60" height="30" />
      </th>
      <td>{'Orchard'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/plant_nursery.gif" width="60" height="30" />
      </th>
      <td>{'Plant nursery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/garden.gif" width="60" height="30" />
      </th>
      <td>{'Garden'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/allotments.gif" width="60" height="30" />
      </th>
      <td>{'Allotments'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/grave_generic.gif" width="60" height="30" />
      </th>
      <td>{'Cemetery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/grave_christian.gif" width="60" height="30" />
      </th>
      <td>{'Christian cemetery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/grave_jewish.gif" width="60" height="30" />
      </th>
      <td>{'Jewish cemetery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/grave_muslim.gif" width="60" height="30" />
      </th>
      <td>{'Muslim cemetery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/quarry.gif" width="60" height="30" />
      </th>
      <td>{'Quarry'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/dog_park.gif" width="60" height="30" />
      </th>
      <td>{'Dog park'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/farmland.gif" />
      </th>
      <td>{'Farmland'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/park.gif" />
      </th>
      <td>{'Park'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/pitch.gif" />
      </th>
      <td>{'Pitch'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/recreation_ground.gif" />
      </th>
      <td>{'Playground, recreation ground, sports centre, stadium, water park'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/golf_course.gif" />
      </th>
      <td>{'Golf course'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/university.gif" />
      </th>
      <td>{'Hospital, university, college, school'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/place_of_worship_area.gif" />
      </th>
      <td>{'Place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/residential_area.gif" />
      </th>
      <td>{'Residential area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/retail_area.gif" />
      </th>
      <td>{'Retail area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/commercial_area.gif" />
      </th>
      <td>{'Commercial area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/industrial_area.gif" />
      </th>
      <td>{'Industrial area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/rest_area.svg" />
      </th>
      <td>{'Rest area, service area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/caravan_site.gif" />
      </th>
      <td>{'Caravan site'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/apron.gif" />
      </th>
      <td>{'Airport apron'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/substation.gif" />
      </th>
      <td>{'Substation, plant, generator'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bridge.gif" />
      </th>
      <td>{'Bridge area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/tree_row.gif" width="70" />
      </th>
      <td>{'Tree row'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/hedge.gif" width="70" />
      </th>
      <td>{'Hedge'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/embankment.gif" width="70" />
      </th>
      <td>{'Embankment'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/cliff.gif" width="70" />
      </th>
      <td>{'Cliff'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/arete.gif" width="70" />
      </th>
      <td>{'Arete'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/powerline_powertower.gif" width="70" />
      </th>
      <td>{'Powerline with powertower'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/powerline_powerpole.gif" width="70" />
      </th>
      <td>{'Powerline with powerpole'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/fence.gif" width="70" />
      </th>
      <td>{'Fence, wall, retaining wall, trench'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/city_wall.gif" width="70" />
      </th>
      <td>{'City wall'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/guard_rail.gif" width="70" />
      </th>
      <td>{'Guardrail'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/waterslide.gif" width="70" />
      </th>
      <td>{'Waterslide'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/river.gif" />
      </th>
      <td>{'River'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/river_intermittent.gif" width="70" />
      </th>
      <td>{'Intermittent river'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/river_tunnel.gif" width="70" />
      </th>
      <td>{'River with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/stream.gif" width="70" />
      </th>
      <td>{'Stream'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/stream_intermittent.gif" width="70" />
      </th>
      <td>{'Intermittent stream'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/stream_tunnel.gif" width="70" />
      </th>
      <td>{'Stream with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/canal.gif" width="70" />
      </th>
      <td>{'Canal'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/canal_intermittent.gif" width="70" />
      </th>
      <td>{'Intermittent canal'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/canal_tunnel.gif" width="90" />
      </th>
      <td>{'Canal with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/ditch.gif" />
      </th>
      <td>{'Ditch, drain'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/ditch_intermittent.gif" width="70" />
      </th>
      <td>{'Intermittent ditch, drain'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/ditch_tunnel.gif" width="90" />
      </th>
      <td>{'Ditch, drain with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/railway_station.gif" width="70" />
      </th>
      <td>{'Train station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/railway_request_station.gif" width="70" />
      </th>
      <td>{'Train request station'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Natural features'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/peak.gif" width="8" />
      </th>
      <td>{'Peak'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/volcano.gif" width="8" />
      </th>
      <td>{'Volcano'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/saddle.gif" width="8" />
      </th>
      <td>{'Saddle'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/cave.gif" width="14" />
      </th>
      <td>{'Cave entrance'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/spring.gif" width="12" />
      </th>
      <td>{'Spring'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/waterfall.gif" width="14" />
      </th>
      <td>{'Waterfall'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/tree.gif" width="16" />
      </th>
      <td>{'Tree'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Civil services, education, worship'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/town_hall.gif" width="14" />
      </th>
      <td>{'Town hall'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/police.gif" width="14" />
      </th>
      <td>{'Police'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/firestation.gif" width="14" />
      </th>
      <td>{'Fire station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/courthouse.gif" width="14" />
      </th>
      <td>{'Court'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/post_office.gif" width="14" />
      </th>
      <td>{'Post office'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/post_box.gif" width="12" />
      </th>
      <td>{'Postbox'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bank.gif" width="14" />
      </th>
      <td>{'Bank'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/atm.gif" width="14" />
      </th>
      <td>{'ATM'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bureau_de_change.gif" width="14" />
      </th>
      <td>{'Bureau de change'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/prison_icon.gif" width="14" />
      </th>
      <td>{'Prison'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/car_wash.gif" width="14" />
      </th>
      <td>{'Car wash'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bicycle_repair_station.gif" width="14" />
      </th>
      <td>{'Bicycle repair station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/rental_car.gif" width="14" />
      </th>
      <td>{'Car rental'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/rental_bicycle.gif" width="14" />
      </th>
      <td>{'Bicycle rental'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/boat_rental.gif" width="14" />
      </th>
      <td>{'Boat rental'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/library.gif" width="14" />
      </th>
      <td>{'Library'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/veterinary.gif" width="14" />
      </th>
      <td>{'Vet'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/officce_point.gif" width="6" />
      </th>
      <td>{'Different kinds of offices, institutions'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/place_of_worship_icon.gif" width="14" />
      </th>
      <td>{'Place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/christian.gif" width="14" />
      </th>
      <td>{'Christian place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/jewish.gif" width="14" />
      </th>
      <td>{'Jewish place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/muslim.gif" width="14" />
      </th>
      <td>{'Muslim place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/hinduist.gif" width="14" />
      </th>
      <td>{'Hindu place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/sikhist.gif" width="14" />
      </th>
      <td>{'Sikh place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/shintoist.gif" width="14" />
      </th>
      <td>{'Shinto place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/buddhist.gif" width="14" />
      </th>
      <td>{'Buddhist place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/taoist.gif" width="14" />
      </th>
      <td>{'Taoist place of worship'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Healthcare, social services'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/pharmacy.gif" width="14" />
      </th>
      <td>{'Pharmacy'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/hospital.gif" width="14" />
      </th>
      <td>{'Hospital'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/doctors.gif" width="14" />
      </th>
      <td>{'Doctor, clinic'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/dentist_point.gif" width="6" />
      </th>
      <td>{'Dentist (zoom 17)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/dentist.gif" width="14" />
      </th>
      <td>{'Dentist'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/social_facility.gif" width="14" />
      </th>
      <td>{'Social facility'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Accomodation, shelters'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/hotel.gif" width="14" />
      </th>
      <td>{'Hotel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/guest_house.gif" width="14" />
      </th>
      <td>{'Guest house'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/hostel.gif" width="14" />
      </th>
      <td>{'Hostel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/motel.gif" width="14" />
      </th>
      <td>{'Motel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/chalet.gif" width="14" />
      </th>
      <td>{'Chalet'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/alpinehut.gif" width="14" />
      </th>
      <td>{'Mountain hut'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/wilderness_hut.gif" width="14" />
      </th>
      <td>{'Wilderness hut'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/camping.gif" width="14" />
      </th>
      <td>{'Camping'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/caravan_park.gif" width="14" />
      </th>
      <td>{'Caravan park'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/shelter.gif" width="14" />
      </th>
      <td>{'Shelter'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/hunting_stand.gif" width="14" />
      </th>
      <td>{'Hunting stand'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Culture, entertainment, leisure, sport, tourism'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/museum.gif" width="14" />
      </th>
      <td>{'Museum'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/theatre.gif" width="14" />
      </th>
      <td>{'Theatre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/cinema.gif" width="14" />
      </th>
      <td>{'Cinema'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/gallery.gif" width="14" />
      </th>
      <td>{'Gallery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/arts_centre.gif" width="14" />
      </th>
      <td>{'Arts centre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/artwork.gif" width="14" />
      </th>
      <td>{'Artwork'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/community_centre.gif" width="14" />
      </th>
      <td>{'Community centre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/casino.gif" width="14" />
      </th>
      <td>{'Casino'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/nightclub.gif" width="14" />
      </th>
      <td>{'Nightclub'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/playground.gif" width="14" />
      </th>
      <td>{'Playground'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/fitness.gif" width="14" />
      </th>
      <td>{'Outdoor gym, fitness centre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/golf.gif" width="20" />
      </th>
      <td>{'Golf course'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/miniature_golf.gif" width="20" />
      </th>
      <td>{'Minigolf'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/water_park.gif" width="14" />
      </th>
      <td>{'Swimming area, water park'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/beach_resort.gif" width="14" />
      </th>
      <td>{'Beach resort'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/fishing.gif" width="14" />
      </th>
      <td>{'Fishing area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/picnic.gif" width="14" />
      </th>
      <td>{'Picnic site'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/firepit.gif" width="14" />
      </th>
      <td>{'Fire pit'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/viewpoint.gif" width="14" />
      </th>
      <td>{'Viewpoint'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/office.gif" width="14" />
      </th>
      <td>{'Tourist info'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/fort.gif" width="14" />
      </th>
      <td>{'Fort'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/memorial.gif" width="14" />
      </th>
      <td>{'Memorial'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/monument.gif" width="14" />
      </th>
      <td>{'Monument'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/obelisk.gif" width="14" />
      </th>
      <td>{'Obelisk'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/plaque.gif" width="12" />
      </th>
      <td>{'Plaque'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/archaeological_site.gif" width="14" />
      </th>
      <td>{'Archaeological site'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/slipway.gif" width="14" />
      </th>
      <td>{'Slipway'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Transport'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/parking.gif" width="12" />
      </th>
      <td>{'Parking'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bicycle_parking.gif" width="14" />
      </th>
      <td>{'Bicycle parking'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bicycle_parking_private.gif" width="14" />
      </th>
      <td>{'Bicycle private parking'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/motorcycle_parking.gif" width="14" />
      </th>
      <td>{'Motorcycle parking'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bus_station.gif" width="14" />
      </th>
      <td>{'Bus station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bus_stop.gif" width="10" />
      </th>
      <td>{'Bus stop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/taxi.gif" width="14" />
      </th>
      <td>{'Taxi'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/fuel.gif" width="14" />
      </th>
      <td>{'Petrol station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/charging_station.gif" width="14" />
      </th>
      <td>{'Charging station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/traffic_light.gif" width="5" height="13" />
      </th>
      <td>{'Traffic lights'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/level_crossing.gif" width="11" />
      </th>
      <td>{'Level crossing'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/mini_roundabout.gif" width="9" />
      </th>
      <td>{'Mini roundabout'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/aerodrome.gif" width="14" />
      </th>
      <td>{'Airport'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/helipad.gif" width="16" />
      </th>
      <td>{'Helipad'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/ferry.gif" width="14" />
      </th>
      <td>{'Ferry terminal'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/entrance.gif" width="10" />
      </th>
      <td>{'Subway entrance'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/elevator.gif" width="12" />
      </th>
      <td>{'Elevator'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/toll_booth.gif" width="14" />
      </th>
      <td>{'Tollbooth'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Food & Drink'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/food_point.gif" width="6" />
      </th>
      <td>{'Amenities related to food or beverage (zoom 17)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bar.gif" width="12" />
      </th>
      <td>{'Bar'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/cafe.gif" width="14" />
      </th>
      <td>{'Cafe'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/biergarten.gif" width="14" />
      </th>
      <td>{'Beer garden'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/pub.gif" width="14" />
      </th>
      <td>{'Pub'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/fast_food.gif" width="14" />
      </th>
      <td>{'Fast food'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/restaurant.gif" width="14" />
      </th>
      <td>{'Restaurant'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/ice_cream.gif" width="14" />
      </th>
      <td>{'Ice cream'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Shopping'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/supermarket.gif" width="14" />
      </th>
      <td>{'Supermarket'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/point.gif" width="6" />
      </th>
      <td>{'Shops (zoom 17, from zoom 18 for shops without icon)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/alcohol.gif" width="14" />
      </th>
      <td>{'Alcohol shop, wine shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/art.gif" width="14" />
      </th>
      <td>{'Art shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bakery.gif" width="14" />
      </th>
      <td>{'Bakery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/beauty.gif" width="14" />
      </th>
      <td>{'Beauty salon'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bicycle.gif" width="14" />
      </th>
      <td>{'Bike shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bookmaker.gif" width="14" />
      </th>
      <td>{'Betting shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/books.gif" width="14" />
      </th>
      <td>{'Bookshop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/butcher.gif" width="14" />
      </th>
      <td>{'Butcher'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/car.gif" width="14" />
      </th>
      <td>{'Car shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/car_parts.gif" width="14" />
      </th>
      <td>{'Car parts shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/car_repair.gif" width="14" />
      </th>
      <td>{'Car repair'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/charity.gif" width="14" />
      </th>
      <td>{'Charity shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/chemist.gif" width="14" />
      </th>
      <td>{'Chemist'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/clothes.gif" width="14" />
      </th>
      <td>{'Clothes shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/coffee.gif" width="14" />
      </th>
      <td>{'Coffee shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/computer.gif" width="14" />
      </th>
      <td>{'Computer store'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/convenience.gif" width="14" />
      </th>
      <td>{'Convenience store'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/copyshop.gif" width="14" />
      </th>
      <td>{'Copy shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/deli.gif" width="14" />
      </th>
      <td>{'Deli'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/department_store.gif" width="14" />
      </th>
      <td>{'Department store'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/diy.gif" width="14" />
      </th>
      <td>{'Do-it-yourself store, hardware store'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/electronics.gif" width="14" />
      </th>
      <td>{'Electronics shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/seafood.gif" width="14" />
      </th>
      <td>{'Fishmonger'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/florist.gif" width="14" />
      </th>
      <td>{'Florist'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/furniture.gif" width="14" />
      </th>
      <td>{'Furniture shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/garden_centre.gif" width="14" />
      </th>
      <td>{'Garden centre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/gift.gif" width="14" />
      </th>
      <td>{'Gift shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/greengrocer.gif" width="14" />
      </th>
      <td>{'Greengrocery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/hairdresser.gif" width="14" />
      </th>
      <td>{'Hairdresser'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/hifi.gif" width="14" />
      </th>
      <td>{'Hi-fi shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/trade.gif" width="14" />
      </th>
      <td>{'Home improvement store'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/jewelry.gif" width="14" />
      </th>
      <td>{'Jewellery shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/laundry.gif" width="14" />
      </th>
      <td>{'Laundry, dry-cleaner'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/marketplace.gif" width="14" />
      </th>
      <td>{'Marketplace'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/mobile_phone.gif" width="14" />
      </th>
      <td>{'Mobile phone shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/motorcycle.gif" width="14" />
      </th>
      <td>{'Motorcycle shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/music.gif" width="14" />
      </th>
      <td>{'Music shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/newsagent.gif" width="14" />
      </th>
      <td>{'Newsagent'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/optician.gif" width="14" />
      </th>
      <td>{'Optician'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/outdoor.gif" width="14" />
      </th>
      <td>{'Outdoor shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/perfumery.gif" width="14" />
      </th>
      <td>{'Perfumery, cosmetics shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/pet.gif" width="14" />
      </th>
      <td>{'Pet shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/photo.gif" width="14" />
      </th>
      <td>{'Photography shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/shoes.gif" width="14" />
      </th>
      <td>{'Shoe shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/sports.gif" width="14" />
      </th>
      <td>{'Sport shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/stationery.gif" width="14" />
      </th>
      <td>{'Stationery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/tobacco.gif" width="14" />
      </th>
      <td>{'Tobacconist'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/toys.gif" width="14" />
      </th>
      <td>{'Toy shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/travel_agency.gif" width="14" />
      </th>
      <td>{'Travel agency'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/tyres.gif" width="14" />
      </th>
      <td>{'Tyre shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/variety_store.gif" width="14" />
      </th>
      <td>{'Variety store'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Other symbols'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/toilets.gif" width="14" />
      </th>
      <td>{'Toilets'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/drinking_water.gif" width="14" />
      </th>
      <td>{'Drinking water'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bbq.gif" width="14" />
      </th>
      <td>{'Barbecue'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/shower.gif" width="14" />
      </th>
      <td>{'Shower'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/telephone.gif" width="12" />
      </th>
      <td>{'Telephone'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/emergency_phone.gif" width="14" />
      </th>
      <td>{'Emergency phone'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/recycling.gif" width="14" />
      </th>
      <td>{'Recycling'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/waste_basket.gif" width="8" />
      </th>
      <td>{'Wastebasket'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/waste_disposal.gif" width="14" />
      </th>
      <td>{'Waste container'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bench.gif" width="14" />
      </th>
      <td>{'Bench'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/gate.gif" width="6" />
      </th>
      <td>{'Gate'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/lift_gate.gif" width="6" />
      </th>
      <td>{'Lift gate, swing gate'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/kissing_gate.gif" width="12" />
      </th>
      <td>{'Kissing gate'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/stile.gif" width="10" />
      </th>
      <td>{'Stile'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/cattle_grid.gif" width="10" />
      </th>
      <td>{'Cattle grid'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bollard.gif" width="6" />
      </th>
      <td>{'Bollard, block'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/cycle_barrier.gif" width="10" />
      </th>
      <td>{'Cycle barrier'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/fountain.gif" width="14" />
      </th>
      <td>{'Fountain'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/mast.gif" width="14" />
      </th>
      <td>{'Mast'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/communications_tower.gif" width="14" />
      </th>
      <td>{'Communication tower'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/telescope_dish.gif" width="14" />
      </th>
      <td>{'Radio telescope'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/telescope_dome.gif" width="14" />
      </th>
      <td>{'Observatory'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/water_tower.gif" width="14" />
      </th>
      <td>{'Water tower'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/windmill.gif" width="14" />
      </th>
      <td>{'Windmill'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/lighthouse.gif" width="14" />
      </th>
      <td>{'Lighthouse'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/crane.gif" width="14" />
      </th>
      <td>{'Crane'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/generator_wind.gif" width="14" />
      </th>
      <td>{'Wind generator'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/cross.gif" width="7" />
      </th>
      <td>{'Cross, wayside cross'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/mine.gif" width="14" />
      </th>
      <td>{'Mineshaft, adit'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bunker.gif" width="14" />
      </th>
      <td>{'Bunker'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Bike trails'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/bike_trail.gif" width="70" />
      </th>
      <td>{'Cycleway'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Hike waymarked'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/hike_red.gif" width="70" />
      </th>
      <td>{'Red hiking route'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/hike_blue.gif" width="70" />
      </th>
      <td>{'Blue hiking route'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/street/images/legend/hike_green.gif" width="70" />
      </th>
      <td>{'Green hiking route'}</td>
    </tr>
  </tbody>
)
