export const WalkaboutLegendBody = () => (
  <tbody>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/motorway.gif" />
      </th>
      <td>{'Motorway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/primary.gif" />
      </th>
      <td>{'Primary road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/secondary.gif" />
      </th>
      <td>{'Secondary road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/tertiary.gif" />
      </th>
      <td>{'Tertiary road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/minor_road.gif" />
      </th>
      <td>{'Minor road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/service.gif" />
      </th>
      <td>{'Service'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/motorway_tunnel.gif" />
      </th>
      <td>{'Motorway with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/primary_tunnel.gif" />
      </th>
      <td>{'Primary road with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/secondary_tunnel.gif" />
      </th>
      <td>{'Secondary road with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/tertiary_tunnel.gif" />
      </th>
      <td>{'Tertiary road with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/minor_road_tunnel.gif" />
      </th>
      <td>{'Minor road with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/oneway.gif" width="70" />
      </th>
      <td>{'Oneway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bridleway.gif" width="70" />
      </th>
      <td>{'Footway, path, pedestrian street, cycleway, bridleway, track'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/steps.gif" width="70" />
      </th>
      <td>{'Steps'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/racetrack.gif" width="70" />
      </th>
      <td>{'Recreation track'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/runway.gif" />
      </th>
      <td>{'Runway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/taxiway.gif" />
      </th>
      <td>{'Taxiway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/rail.gif" />
      </th>
      <td>{'Railway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/rail_service.gif" />
      </th>
      <td>{'Siding, spur, yard, crossover'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/tram.gif" />
      </th>
      <td>{'Tram, light rail, subway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/piste.gif" />
      </th>
      <td>{'Piste'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/piste_easy.gif" />
      </th>
      <td>{'Piste (difficulty: easy)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/piste_intermediate.gif" />
      </th>
      <td>{'Piste (difficulty: intermediate)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/piste_advanced.gif" />
      </th>
      <td>{'Piste (difficulty: advanced, expert)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/gondola.gif" />
      </th>
      <td>{'Chairlift, gondola lift, cable car'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/aerialway.gif" />
      </th>
      <td>
        {
          'Goods cable lift, mixed lift, drag lift, t-bar lift, j-bar lift, platter lift, magic carpet, rope tow, zipline'
        }
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/pier.gif" />
      </th>
      <td>{'Pier'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/ferry.gif" />
      </th>
      <td>{'Ferry route'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/water.gif" />
      </th>
      <td>{'Ocean, sea, lake'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/glacier.gif" />
      </th>
      <td>{'Glacier'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/intermittent.gif" width="70" height="40" />
      </th>
      <td>{'Intermittent water areas'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/forest.gif" />
      </th>
      <td>{'Forest'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/scree.gif" />
      </th>
      <td>{'Scree'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/scrub.gif" />
      </th>
      <td>{'Scrub, pier area, bridge area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/wetland.gif" />
      </th>
      <td>{'Wetland'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/beach.gif" />
      </th>
      <td>{'Beach'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/grass.gif" />
      </th>
      <td>{'Grass, meadow'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/allotments.gif" />
      </th>
      <td>{'Garden, allotments'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/cemetery.gif" />
      </th>
      <td>{'Cemetery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/farmland.gif" />
      </th>
      <td>{'Farmland'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/park.gif" />
      </th>
      <td>{'Park'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/pitch.gif" />
      </th>
      <td>{'Pitch'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/playground.gif" />
      </th>
      <td>{'Playground, village green'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/recreation_ground.gif" />
      </th>
      <td>{'Recreation ground'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/golf_course.gif" />
      </th>
      <td>{'Golf course'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/hospital.gif" />
      </th>
      <td>{'Hospital'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/university.gif" />
      </th>
      <td>
        {
          'Stadium, university, college, school, airport, military area, place of worship, parking, industrial area, substation, plant, generator, railway area, wastewater plant, works, waterworks, campsite, theme park, maze'
        }
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/pedestrian.gif" />
      </th>
      <td>{'Pedestrian area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/zoo.gif" />
      </th>
      <td>{'Zoo, wildlife park'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/tree_row.gif" />
      </th>
      <td>{'Tree row'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/hedge.gif" />
      </th>
      <td>{'Hedge'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/fence.gif" />
      </th>
      <td>{'Fence'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/snow_fence.gif" />
      </th>
      <td>{'Snow fence, dam'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/retaining_wall.gif" />
      </th>
      <td>{'Retaining wall'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/city_wall.gif" />
      </th>
      <td>{'City wall'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/river.gif" />
      </th>
      <td>{'River, stream, canal, ditch'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/river_intermittent.gif" width="70" />
      </th>
      <td>{'Drain + intermittent: river, stream, canal, ditch, drain'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Natural features'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/peak.gif" width="20" />
      </th>
      <td>{'Peak'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/volcano.gif" width="20" />
      </th>
      <td>{'Volcano'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/saddle.gif" width="20" />
      </th>
      <td>{'Saddle'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/cave_entrance.gif" width="20" />
      </th>
      <td>{'Cave entrance'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/spring.gif" width="20" />
      </th>
      <td>{'Spring'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/hot_spring.gif" width="20" />
      </th>
      <td>{'Hot spring'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/waterfall.gif" width="20" />
      </th>
      <td>{'Waterfall'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/geyser.gif" width="20" />
      </th>
      <td>{'Geyser'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/rock.gif" width="20" />
      </th>
      <td>{'Rock'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/stone.gif" width="20" />
      </th>
      <td>{'Stone'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/sinkhole.gif" width="20" />
      </th>
      <td>{'Sinkhole'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/tree.gif" width="16" />
      </th>
      <td>{'Tree'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/wetland_icon.gif" width="20" />
      </th>
      <td>{'Wetland'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/beach_icon.gif" width="20" />
      </th>
      <td>{'Beach'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/dune.gif" width="20" />
      </th>
      <td>{'Dune'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Civil services, education, worship'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/town_hall.gif" width="20" />
      </th>
      <td>{'Town hall'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/police.gif" width="20" />
      </th>
      <td>{'Police'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/fire_station.gif" width="20" />
      </th>
      <td>{'Fire station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/courthouse.gif" width="20" />
      </th>
      <td>{'Court, lawyer office'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/post.gif" width="20" />
      </th>
      <td>{'Post office'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/post_box.gif" width="20" />
      </th>
      <td>{'Postbox'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bank.gif" width="16" />
      </th>
      <td>{'Bank'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/atm.gif" width="20" />
      </th>
      <td>{'ATM'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bureau_de_change.gif" width="20" />
      </th>
      <td>{'Bureau de change'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/money_transfer.gif" width="20" />
      </th>
      <td>{'Money transfer'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/prison.gif" width="20" />
      </th>
      <td>{'Prison'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/ranger_station.gif" width="20" />
      </th>
      <td>{'Ranger station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/car_wash.gif" width="20" />
      </th>
      <td>{'Car wash'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bicycle_repair_station.gif" width="20" />
      </th>
      <td>{'Bicycle repair station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/car_rental.gif" width="20" />
      </th>
      <td>{'Car rental'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/car_sharing.gif" width="20" />
      </th>
      <td>{'Car sharing'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bicycle_rental.gif" width="20" />
      </th>
      <td>{'Bicycle rental'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/boat_rental.gif" width="20" />
      </th>
      <td>{'Boat rental'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/boat_storage.gif" width="20" />
      </th>
      <td>{'Boat storage'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/university_icon.gif" width="20" />
      </th>
      <td>{'College, university'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/school.gif" width="20" />
      </th>
      <td>{'Kindergarten, school'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/library.gif" width="20" />
      </th>
      <td>{'Library, bookshop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/childcare.gif" width="20" />
      </th>
      <td>{'Childcare, day care'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/veterinary.gif" width="20" />
      </th>
      <td>{'Vet'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/place_of_worship.gif" width="20" />
      </th>
      <td>{'Place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/christian.gif" width="20" />
      </th>
      <td>{'Christian place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/jewish.gif" width="20" />
      </th>
      <td>{'Jewish place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/muslim.gif" width="20" />
      </th>
      <td>{'Muslim place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/hindu.gif" width="20" />
      </th>
      <td>{'Hindu place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/sikh.gif" width="20" />
      </th>
      <td>{'Sikh place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/shinto.gif" width="20" />
      </th>
      <td>{'Shinto place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/buddhist.gif" width="20" />
      </th>
      <td>{'Buddhist place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/taoist.gif" width="20" />
      </th>
      <td>{'Taoist place of worship'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Healthcare, social services'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/pharmacy.gif" width="20" />
      </th>
      <td>{'Pharmacy, chemist'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/hospital_icon.gif" width="20" />
      </th>
      <td>{'Hospital'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/health_centre.gif" width="20" />
      </th>
      <td>{'Health centre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/doctors.gif" width="20" />
      </th>
      <td>{'Doctor, clinic'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/dentist.gif" width="20" />
      </th>
      <td>{'Dentist'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/blood_bank.gif" width="20" />
      </th>
      <td>{'Blood bank'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/midwife.gif" width="20" />
      </th>
      <td>{'Midwife'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/social_facility.gif" width="20" />
      </th>
      <td>{'Social facility'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/ambulatory_care.gif" width="20" />
      </th>
      <td>{'Ambulatory care'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/assisted_living.gif" width="20" />
      </th>
      <td>{'Assisted living'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/food_bank.gif" width="20" />
      </th>
      <td>{'Food bank'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/group_home.gif" width="20" />
      </th>
      <td>{'Group home'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/nursing_home.gif" width="20" />
      </th>
      <td>{'Nursing home'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/outreach.gif" width="20" />
      </th>
      <td>{'Outreach'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/soup_kitchen.gif" width="20" />
      </th>
      <td>{'Soup kitchen'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/workshop.gif" width="20" />
      </th>
      <td>{'Workshop'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Accomodation, shelters'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/hotel.gif" width="20" />
      </th>
      <td>{'Hotel, hostel, motel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/guest_house.gif" width="20" />
      </th>
      <td>{'Guest house'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/chalet.gif" width="20" />
      </th>
      <td>{'Chalet'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/alpine_hut.gif" width="20" />
      </th>
      <td>{'Mountain hut'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/wilderness_hut.gif" width="20" />
      </th>
      <td>{'Wilderness hut'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/campsite.gif" width="20" />
      </th>
      <td>{'Camping'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/caravan_site.gif" width="20" />
      </th>
      <td>{'Caravan park'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/shelter.gif" width="20" />
      </th>
      <td>{'Shelter'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/hunting_stand.gif" width="20" />
      </th>
      <td>{'Hunting stand'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Culture, entertainment, leisure, sport, tourism'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/museum.gif" width="20" />
      </th>
      <td>{'Museum'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/theatre.gif" width="20" />
      </th>
      <td>{'Theatre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/cinema.gif" width="20" />
      </th>
      <td>{'Cinema'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/gallery.gif" width="20" />
      </th>
      <td>{'Gallery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/arts_centre.gif" width="20" />
      </th>
      <td>{'Arts centre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/artwork.gif" width="20" />
      </th>
      <td>{'Artwork'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/community_centre.gif" width="20" />
      </th>
      <td>{'Community centre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/gambling.gif" width="20" />
      </th>
      <td>{'Gambling'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/nightclub.gif" width="20" />
      </th>
      <td>{'Nightclub'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/studio.gif" width="20" />
      </th>
      <td>{'Studio'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/park_icon.gif" width="20" />
      </th>
      <td>{'National park, protected area, park'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/nature_reserve_icon.gif" width="20" />
      </th>
      <td>{'Nature reserve, forest'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/playground_icon.gif" width="20" />
      </th>
      <td>{'Playground'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/sports.gif" width="18" />
      </th>
      <td>{'Recreation ground, sports centre, sport shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/garden.gif" width="20" />
      </th>
      <td>{'Garden'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/fitness.gif" width="20" />
      </th>
      <td>{'Outdoor gym, fitness centre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/recreation_track.gif" width="20" />
      </th>
      <td>{'Recreation track'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/dog_park.gif" width="20" />
      </th>
      <td>{'Dog park'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/golf_course_icon.gif" width="20" />
      </th>
      <td>{'Golf course'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/miniature_golf.gif" width="20" />
      </th>
      <td>{'Minigolf'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/pitch_soccer.gif" width="20" />
      </th>
      <td>{'Football field'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/pitch_american_football.gif" width="20" />
      </th>
      <td>{'American football field'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/pitch_tennis.gif" width="20" />
      </th>
      <td>{'Tennis court'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/pitch_basketball.gif" width="20" />
      </th>
      <td>{'Basketball court'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/pitch_baseball.gif" width="20" />
      </th>
      <td>{'Baseball field'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/pitch_cricket.gif" width="20" />
      </th>
      <td>{'Cricket field'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/pitch_golf.gif" width="20" />
      </th>
      <td>{'Green, golf shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/swimming_area.gif" width="20" />
      </th>
      <td>{'Swimming area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/water_park.gif" width="20" />
      </th>
      <td>{'Water park'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/beach_resort.gif" width="20" />
      </th>
      <td>{'Beach resort'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/fishing.gif" width="20" />
      </th>
      <td>{'Fishing area, fishing shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/picnic_site.gif" width="20" />
      </th>
      <td>{'Picnic site'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/firepit.gif" width="20" />
      </th>
      <td>{'Fire pit'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/viewpoint.gif" width="20" />
      </th>
      <td>{'Viewpoint'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/information.gif" width="20" />
      </th>
      <td>{'Tourist info'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/fort.gif" width="20" />
      </th>
      <td>{'Fort'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/memorial.gif" width="20" />
      </th>
      <td>{'Memorial, monument'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/obelisk.gif" width="20" />
      </th>
      <td>{'Obelisk'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/plaque.gif" width="20" />
      </th>
      <td>{'Plaque'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/archaeological_site.gif" width="20" />
      </th>
      <td>{'Archaeological site'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/historical.gif" width="20" />
      </th>
      <td>{'Historical'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/battlefield.gif" width="20" />
      </th>
      <td>{'Battlefield'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/stadium.gif" width="20" />
      </th>
      <td>{'Stadium'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/winter_sports.gif" width="20" />
      </th>
      <td>{'Winter sports centre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/ski_rental.gif" width="20" />
      </th>
      <td>{'Ski rental'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/ski_school.gif" width="20" />
      </th>
      <td>{'Ski school'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/marina.gif" width="20" />
      </th>
      <td>{'Marina'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/dive_centre.gif" width="20" />
      </th>
      <td>{'Dive centre, scuba diving equipment'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/aquarium.gif" width="20" />
      </th>
      <td>{'Aquarium'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/horse_range.gif" width="20" />
      </th>
      <td>{'Horse riding'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/zoo_icon.gif" width="20" />
      </th>
      <td>{'Zoo'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/aviary.gif" width="20" />
      </th>
      <td>{'Aviary'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/petting_zoo.gif" width="20" />
      </th>
      <td>{'Petting zoo'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/wildlife_park.gif" width="20" />
      </th>
      <td>{'Wildlife park'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/theme_park.gif" width="20" />
      </th>
      <td>{'Theme park, amusement ride'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/attraction.gif" width="20" />
      </th>
      <td>{'Attraction'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/carousel.gif" width="20" />
      </th>
      <td>{'Carousel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/roller_coaster.gif" width="20" />
      </th>
      <td>{'Roller coaster'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/summer_toboggan.gif" width="20" />
      </th>
      <td>{'Summer toboggan'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/maze.gif" width="20" />
      </th>
      <td>{'Maze'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/water_slide.gif" width="20" />
      </th>
      <td>{'Water slide'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/slipway.gif" width="20" />
      </th>
      <td>{'Slipway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/whitewater_put_in.gif" width="20" />
      </th>
      <td>{'Put in (whitewater)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/whitewater_egress.gif" width="20" />
      </th>
      <td>{'Egress (whitewater)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/whitewater_put_in_egress.gif" width="20" />
      </th>
      <td>{'Put in, egress (whitewater)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/whitewater_rapid.gif" width="20" />
      </th>
      <td>{'Rapid (whitewater)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/whitewater_hazard.gif" width="20" />
      </th>
      <td>{'Hazard (whitewater)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/summer_camp.gif" width="20" />
      </th>
      <td>{'Summer camp'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Transport'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/parking.gif" width="16" />
      </th>
      <td>{'Parking'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/parking_garage.gif" width="20" />
      </th>
      <td>{'Parking garage'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bicycle_parking.gif" width="20" />
      </th>
      <td>{'Bicycle parking'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/motorcycle_parking.gif" width="20" />
      </th>
      <td>{'Motorcycle parking'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bus_stop.gif" width="20" />
      </th>
      <td>{'Bus station, bus stop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/station.gif" width="20" />
      </th>
      <td>{'Train station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/halt.gif" width="20" />
      </th>
      <td>{'Train request station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/tram_stop.gif" width="20" />
      </th>
      <td>{'Tram stop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/taxi.gif" width="20" />
      </th>
      <td>{'Taxi'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/fuel.gif" width="20" />
      </th>
      <td>{'Petrol station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/charging_station.gif" width="20" />
      </th>
      <td>{'Charging station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/traffic_lights.gif" width="20" />
      </th>
      <td>{'Traffic lights'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/level_crossing.gif" width="14" />
      </th>
      <td>{'Level crossing'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/mini_roundabout.gif" width="20" />
      </th>
      <td>{'Mini roundabout'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/airport.gif" width="20" />
      </th>
      <td>{'Airport'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/helipad.gif" width="20" />
      </th>
      <td>{'Helipad'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/heliport.gif" width="20" />
      </th>
      <td>{'Heliport'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/ferry_terminal.gif" width="20" />
      </th>
      <td>{'Ferry terminal'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/aeroway_gate.gif" width="20" />
      </th>
      <td>{'Aeroway gate'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/subway_entrance.gif" width="20" />
      </th>
      <td>{'Subway entrance'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/elevator.gif" width="20" />
      </th>
      <td>{'Elevator'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/toll_both.gif" width="20" />
      </th>
      <td>{'Tollboth'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/motorway_junction.gif" width="20" />
      </th>
      <td>{'Motorway junction'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/rest_area.gif" width="20" />
      </th>
      <td>{'Rest area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/service_area.gif" width="20" />
      </th>
      <td>{'Service area'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Offices'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/company.gif" width="20" />
      </th>
      <td>{'Company, insurance, office'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/association.gif" width="20" />
      </th>
      <td>{'Association, foundation'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/government.gif" width="20" />
      </th>
      <td>{'Government offices'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/employment_agency.gif" width="20" />
      </th>
      <td>{'Employment agency'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/estate_agent.gif" width="20" />
      </th>
      <td>{'Estate agent'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/notary.gif" width="20" />
      </th>
      <td>{'Notary public'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/ngo.gif" width="20" />
      </th>
      <td>{'NGO'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/consulting.gif" width="20" />
      </th>
      <td>{'Consulting'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/it.gif" width="20" />
      </th>
      <td>{'It office'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/financial.gif" width="20" />
      </th>
      <td>{'Financial company'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/political_party.gif" width="20" />
      </th>
      <td>{'Political party'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/telecommunication.gif" width="20" />
      </th>
      <td>{'Telecommunication company'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/religion.gif" width="20" />
      </th>
      <td>{'Religious community'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/research.gif" width="20" />
      </th>
      <td>{'Research'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/architect.gif" width="20" />
      </th>
      <td>{'Architect'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/accountant.gif" width="20" />
      </th>
      <td>{'Accountant'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/tax_advisor.gif" width="20" />
      </th>
      <td>{'Tax advisor'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/therapist.gif" width="20" />
      </th>
      <td>{'Therapist'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Food & Drink'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bar.gif" width="20" />
      </th>
      <td>{'Bar, pub'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/cafe.gif" width="20" />
      </th>
      <td>{'Cafe'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/biergarten.gif" width="20" />
      </th>
      <td>{'Beer garden, brewery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/fast_food.gif" width="20" />
      </th>
      <td>{'Fast food'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/restaurant.gif" width="20" />
      </th>
      <td>{'Restaurant'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/ice_cream.gif" width="20" />
      </th>
      <td>{'Ice cream'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Craft'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/craft.gif" width="20" />
      </th>
      <td>{'Unspecified craft'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/carpenter.gif" width="20" />
      </th>
      <td>{'Carpenter'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/electrician.gif" width="20" />
      </th>
      <td>{'Electrician'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/hvac.gif" width="20" />
      </th>
      <td>{'HVAC'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/photographer.gif" width="20" />
      </th>
      <td>{'Photographer'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/confectionery.gif" width="20" />
      </th>
      <td>{'Confectionery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/winery.gif" width="20" />
      </th>
      <td>{'Winery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/dressmaker.gif" width="20" />
      </th>
      <td>{'Dressmaker'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/tailor.gif" width="20" />
      </th>
      <td>{'Tailor'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/gardener.gif" width="20" />
      </th>
      <td>{'Gardener'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/stonemason.gif" width="20" />
      </th>
      <td>{'Stonemason'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/plumber.gif" width="20" />
      </th>
      <td>{'Plumber'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/handicraft.gif" width="20" />
      </th>
      <td>{'Handicraft'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/shoemaker.gif" width="20" />
      </th>
      <td>{'Shoemaker'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/pottery.gif" width="20" />
      </th>
      <td>{'Pottery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/metal_construction.gif" width="20" />
      </th>
      <td>{'Metal construction'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/sawmill.gif" width="20" />
      </th>
      <td>{'Sawmill'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Shopping'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/shop.gif" width="20" />
      </th>
      <td>{'Shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/closed.gif" width="16" />
      </th>
      <td>{'Closed shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/alcohol.gif" width="20" />
      </th>
      <td>{'Alcohol shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/art.gif" width="20" />
      </th>
      <td>{'Art shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bakery.gif" width="20" />
      </th>
      <td>{'Bakery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/beauty.gif" width="20" />
      </th>
      <td>{'Beauty salon'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bicycle.gif" width="20" />
      </th>
      <td>{'Bike shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/gas_canister.gif" width="20" />
      </th>
      <td>{'Bottled gas shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/butcher.gif" width="20" />
      </th>
      <td>{'Butcher'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/car_parts.gif" width="20" />
      </th>
      <td>{'Car parts shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/car_repair.gif" width="20" />
      </th>
      <td>{'Car repair'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/car.gif" width="20" />
      </th>
      <td>{'Car shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/clothes.gif" width="20" />
      </th>
      <td>{'Clothes shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/coffee.gif" width="20" />
      </th>
      <td>{'Coffee shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/computer.gif" width="20" />
      </th>
      <td>{'Computer store, Electronics shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/convenience.gif" width="20" />
      </th>
      <td>{'Convenience store'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/copyshop.gif" width="20" />
      </th>
      <td>{'Copy shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/cosmetics.gif" width="20" />
      </th>
      <td>{'Cosmetics shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/deli.gif" width="20" />
      </th>
      <td>{'Deli'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/mall.gif" width="20" />
      </th>
      <td>{'Department store, mall'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/hardware.gif" width="20" />
      </th>
      <td>{'Do-it-yourself store, hardware store, painter'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/dry_cleaning.gif" width="20" />
      </th>
      <td>{'Dry-cleaner'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/fishmonger.gif" width="20" />
      </th>
      <td>{'Fishmonger'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/florist.gif" width="20" />
      </th>
      <td>{'Florist'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/funeral_directors.gif" width="20" />
      </th>
      <td>{'Funeral home'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/furniture.gif" width="20" />
      </th>
      <td>{'Furniture shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/garden_centre.gif" width="20" />
      </th>
      <td>{'Garden centre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/gift.gif" width="20" />
      </th>
      <td>{'Gift shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/greengrocer.gif" width="20" />
      </th>
      <td>{'Greengrocery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/hairdresser.gif" width="20" />
      </th>
      <td>{'Hairdresser'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/hifi.gif" width="20" />
      </th>
      <td>{'Hi-fi shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/trade.gif" width="20" />
      </th>
      <td>{'Home improvement store'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/hunting.gif" width="20" />
      </th>
      <td>{'Hunting shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/jewellry.gif" width="20" />
      </th>
      <td>{'Jewellery shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/laundry.gif" width="20" />
      </th>
      <td>{'Laundry'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/marketplace.gif" width="20" />
      </th>
      <td>{'Marketplace'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/mobile_phone.gif" width="20" />
      </th>
      <td>{'Mobile phone shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/motorcycle.gif" width="20" />
      </th>
      <td>{'Motorcycle shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/music.gif" width="20" />
      </th>
      <td>{'Music shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/newsagent.gif" width="20" />
      </th>
      <td>{'Newsagent'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/newspaper.gif" width="20" />
      </th>
      <td>{'Newspaper'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/optician.gif" width="20" />
      </th>
      <td>{'Optician'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/outdoor.gif" width="20" />
      </th>
      <td>{'Outdoor shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/perfumery.gif" width="20" />
      </th>
      <td>{'Perfumery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/pet.gif" width="20" />
      </th>
      <td>{'Pet shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/photo.gif" width="20" />
      </th>
      <td>{'Photography shop, photographic laboratory'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/shoes.gif" width="20" />
      </th>
      <td>{'Shoe shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/stationery.gif" width="20" />
      </th>
      <td>{'Stationery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/supermarket.gif" width="20" />
      </th>
      <td>{'Supermarket'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/tobacco.gif" width="20" />
      </th>
      <td>{'Tobacconist'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/toy.gif" width="20" />
      </th>
      <td>{'Toy shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/travel_agency.gif" width="20" />
      </th>
      <td>{'Travel agency'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/tyres.gif" width="20" />
      </th>
      <td>{'Tyre shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/variety_store.gif" width="20" />
      </th>
      <td>{'Variety store'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/wine.gif" width="20" />
      </th>
      <td>{'Wine shop'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Other symbols'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/toilets.gif" width="20" />
      </th>
      <td>{'Toilets'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/drinking_water.gif" width="20" />
      </th>
      <td>{'Drinking water'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/water_point.gif" width="20" />
      </th>
      <td>{'Water point'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/watering_place.gif" width="20" />
      </th>
      <td>{'Watering place'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bbq.gif" width="20" />
      </th>
      <td>{'Barbecue'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/shower.gif" width="20" />
      </th>
      <td>{'Shower'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/telephone.gif" width="20" />
      </th>
      <td>{'Telephone, emergency phone'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/recycling.gif" width="20" />
      </th>
      <td>{'Recycling'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/wastebasket.gif" width="20" />
      </th>
      <td>{'Wastebasket'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/waste_disposal.gif" width="20" />
      </th>
      <td>{'Waste container'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bench.gif" width="20" />
      </th>
      <td>{'Bench'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/lamp.gif" width="20" />
      </th>
      <td>{'Street lamp'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/defibrilator.gif" width="20" />
      </th>
      <td>{'Defibrillator'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/fire_hydrant.gif" width="20" />
      </th>
      <td>{'Fire hydrant'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/gate.gif" width="20" />
      </th>
      <td>{'Gate'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bollard.gif" width="20" />
      </th>
      <td>{'Bollard'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/cycle_barrier.gif" width="20" />
      </th>
      <td>{'Cycle barrier'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/border_control.gif" width="20" />
      </th>
      <td>{'Border control'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/water_well.gif" width="20" />
      </th>
      <td>{'Water well'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/mast.gif" width="20" />
      </th>
      <td>{'Mast'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/communications_tower.gif" width="20" />
      </th>
      <td>{'Communication tower'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/telescope.gif" width="20" />
      </th>
      <td>{'Radio telescope'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/observatory.gif" width="20" />
      </th>
      <td>{'Observatory'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/water_tower.gif" width="20" />
      </th>
      <td>{'Water tower'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/watermill.gif" width="20" />
      </th>
      <td>{'Watermill'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/windmill.gif" width="20" />
      </th>
      <td>{'Windmill'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/lighthouse.gif" width="20" />
      </th>
      <td>{'Lighthouse'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/cross.gif" width="16" />
      </th>
      <td>{'Cross'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/wayside_cross.gif" width="20" />
      </th>
      <td>{'Wayside cross'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/substation.gif" width="20" />
      </th>
      <td>{'Generator, plant, substation, wastewater plant, waterworks, works'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/generator_nuclear.gif" width="20" />
      </th>
      <td>{'Nuclear generator'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/generator_solar.gif" width="20" />
      </th>
      <td>{'Solar generator'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/generator_wind.gif" width="20" />
      </th>
      <td>{'Wind generator'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/power_pole.gif" width="20" />
      </th>
      <td>{'Power pole'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/power_tower.gif" width="20" />
      </th>
      <td>{'Transmission tower'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/pylon.gif" width="20" />
      </th>
      <td>{'Pylon'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/petroleum_well.gif" width="20" />
      </th>
      <td>{'Petroleum well'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/lifeguard_tower.gif" width="20" />
      </th>
      <td>{'Lifeguard tower'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/offshore_platform.gif" width="20" />
      </th>
      <td>{'Offshore platform'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/dam.gif" width="20" />
      </th>
      <td>{'Dam'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/lock.gif" width="20" />
      </th>
      <td>{'Lock'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/cemetery_icon.gif" width="20" />
      </th>
      <td>{'Cemetery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/quarry.gif" width="20" />
      </th>
      <td>{'Quarry, mineshaft, adit'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/allotments_icon.gif" width="20" />
      </th>
      <td>{'Allotments'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/farm.gif" width="20" />
      </th>
      <td>{'Farm'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/slaughterhouse.gif" width="20" />
      </th>
      <td>{'Slaughterhouse'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bunker.gif" width="20" />
      </th>
      <td>{'Bunker'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/airfield.gif" width="20" />
      </th>
      <td>{'Airfield'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/military.gif" width="20" />
      </th>
      <td>{'Military area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/danger_area.gif" width="20" />
      </th>
      <td>{'Danger area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/trail_riding_station.gif" width="20" />
      </th>
      <td>{'Trail riding station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/trailhead.gif" width="20" />
      </th>
      <td>{'Trailhead'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/walking_junction.gif" width="20" />
      </th>
      <td>{'Walking junction'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/generic.gif" width="9" />
      </th>
      <td>{'Generic symbol'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Bike paths'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bike_path_off-road.gif" width="70" />
      </th>
      <td>{'Bike path - off-roads paths (off-road trail)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bike_minor_road_neighborhood_greenway.gif" width="70" />
      </th>
      <td>{'Minor road - residential and living streets'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bike_minor_road_bike-friendly_minor_road.gif" width="70" />
      </th>
      <td>{'Minor road - bike-friendly minor road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bike_major_road_cycle_track.gif" width="70" />
      </th>
      <td>{'Major road - ride your bike separated from cars'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bike_major_road_bike_lanes.gif" width="70" />
      </th>
      <td>{'Major road - ride your bike next to cars'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bike_major_road_bike_route.gif" width="70" />
      </th>
      <td>{'Major road - ride your bike with cars'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/other_off-road_dirt_track.gif" width="70" />
      </th>
      <td>{'Other - off-road dirt track'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/other_footway.gif" width="70" />
      </th>
      <td>{'Other - ride your bike with walkers'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/other_bycicle=no.gif" width="70" />
      </th>
      <td>{'Other - walk your bike'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/other_bike-friendly_minor_road.gif" width="70" />
      </th>
      <td>{'Other - bike-friendly minor road (with buses)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bike_bicycle_parking.gif" width="20" />
      </th>
      <td>{'Bicycle parking'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bike_bicycle_rental.gif" width="20" />
      </th>
      <td>{'Bicycle rental'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bike_bicycle.gif" width="20" />
      </th>
      <td>{'Bike shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bike_drinking_water.gif" width="20" />
      </th>
      <td>{'Drinking water'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bike_toilets.gif" width="20" />
      </th>
      <td>{'Toilets'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bike_cycle_barrier.gif" width="20" />
      </th>
      <td>{'Cycle barrier'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bike_cafe.gif" width="20" />
      </th>
      <td>{'Cafe'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bike_pub.gif" width="20" />
      </th>
      <td>{'Pub'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Bike trails'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/bike_trail.gif" width="70" />
      </th>
      <td>{'Cycleway'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Hike paths'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/footway.gif" width="70" />
      </th>
      <td>{'Footway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/path.gif" width="70" />
      </th>
      <td>{'Path, track'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/track.gif" width="70" />
      </th>
      <td>{'Track, no motor vehicles'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/steps_hike.gif" width="70" />
      </th>
      <td>{'Steps'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/walking_network.gif" width="70" />
      </th>
      <td>{'Walking network'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Hike waymarked'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/hike_red.gif" width="70" />
      </th>
      <td>{'Red hiking route'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/hike_blue.gif" width="70" />
      </th>
      <td>{'Blue hiking route'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/walkabout/images/legend/hike_green.gif" width="70" />
      </th>
      <td>{'Green hiking route'}</td>
    </tr>
  </tbody>
)
