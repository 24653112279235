import { useEffect, useState } from "react";

import {
  faBicycle,
  faCar,
  faChevronDown,
  faWalking,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Checkbox,
  ColorInput,
  Divider,
  Group,
  Menu,
  Spoiler,
  Stack,
  Textarea,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { ContextModalProps } from "@mantine/modals";
import { LinksEditor } from "components/UI/LinksEditor";
import { Routing } from "config/enums/routings";
import { COLOR_SWATCHES } from "config/mantine";
import { useGenericModals } from "hooks/useGenericModals";
import {
  CreateTrackInterface,
  UpdateTrackInterface,
  useUserTracks,
} from "hooks/useUserTracks";
import { useTranslation } from "react-i18next";
import {
  changeTrackColor,
  changeRoutingType,
  rewriteComputedTrackpoints,
  rewriteTrackWaypoints,
} from "stores/routingStore/actions";
import { useRouting } from "stores/routingStore/RoutingContext";
import { TrackWithMetadata } from "types/app";
import { convertTrackToRoutable } from "utils/api/convertTrackToRoutable";
import { getErrorMsg } from "utils/getErrorMsg/getErrorMsg";

type Status = "saving" | "duplicating" | null;

export const EditTrackModal = ({
  context,
  id,
}: ContextModalProps<Record<string, never>>) => {
  const { selectedTrack } = useUserTracks();
  // This is a TS hackifx, unfortunately, since the coding inside the hook is not the best
  const track = selectedTrack as TrackWithMetadata;

  const [status, setStatus] = useState<Status>(null);
  const [links, setLinks] = useState(track.links);
  const {
    dispatch,
    state: { trackColor },
  } = useRouting();
  const [selectedColor, setSelectedColor] = useState(trackColor || track.color);
  const { openConfirmTrackDeleteModal } = useGenericModals();
  const { openErrorModal } = useGenericModals();
  const { updateTrack, createTrack, deleteTrack } = useUserTracks();
  const {
    state: {
      computedTrackpoints,
      nonRoutableTrackMetadata,
      trackWaypoints,
      routing,
      elevations,
      trackMeta,
    },
    changeRouting,
    dispatch: routingDispatch,
  } = useRouting();
  const { t } = useTranslation();

  useEffect(() => {
    setLinks(track.links);
  }, [track.links]);

  const form = useForm({
    initialValues: {
      name: track.name,
      desc: track.desc,
      pub: track.pub,
      src: track.src,
      cmt: track.cmt,
      type: track.type,
      number: track.number,
    },
  });

  const handleDelete = () => {
    openConfirmTrackDeleteModal({
      count: 1,
      onConfirm: () => {
        deleteTrack(track.id);
        context.closeModal(id);
      },
    });
  };

  const getDataToSave = (): CreateTrackInterface => ({
    trackPointsEle: elevations,
    trackPointsCadence: nonRoutableTrackMetadata.trackPointsCadence,
    trackPointsHeartRate: nonRoutableTrackMetadata.trackPointsHeartRate,
    trackPointsPower: nonRoutableTrackMetadata.trackPointsPower,
    trackPointsSpeed:
      trackMeta.trackPointsSpeed ?? nonRoutableTrackMetadata.trackPointsSpeed,
    trackPointsTemperature: nonRoutableTrackMetadata.trackPointsTemperature,
    trackPointsTime:
      trackMeta.trackPointsTime ??
      (track.trackPointsTime &&
      nonRoutableTrackMetadata.trackPointsTimeDatapoints
        ? {
            ...track.trackPointsTime,
            datapoints: nonRoutableTrackMetadata.trackPointsTimeDatapoints,
          }
        : undefined),
    ...form.values,
    links: links.filter((link) => link.length > 0),
    controlPoints: routing === Routing.none ? [] : trackWaypoints,
    trackPoints:
      routing === Routing.none ? trackWaypoints : computedTrackpoints,
    color: selectedColor,
    routing,
  });

  const saveTrack = async ({ saveAsNew }: { saveAsNew: boolean }) => {
    setStatus(saveAsNew ? "duplicating" : "saving");
    const data = getDataToSave();
    try {
      if (saveAsNew) {
        await createTrack(data);
      } else {
        await updateTrack(track.id, data);
      }

      dispatch(changeTrackColor(selectedColor));
    } catch (error) {
      console.error("error updating track");
    }
    context.closeModal(id);
  };

  const handleFormSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    saveTrack({ saveAsNew: false });
  };

  const handleConvertToNonRoutable = () => {
    changeRouting(Routing.none, undefined, async () => {
      const isRoutable = routing !== Routing.none;

      try {
        const data: UpdateTrackInterface = {
          ...form.values,
          links,
          controlPoints: !isRoutable ? [] : trackWaypoints,
          trackPoints: !isRoutable ? trackWaypoints : computedTrackpoints,
          color: selectedColor,
          trackPointsEle: elevations,
          trackPointsSpeed:
            trackMeta.trackPointsSpeed ?? track.trackPointsSpeed,
          trackPointsTime: trackMeta.trackPointsTime ?? track.trackPointsTime,
        };
        await updateTrack(track.id, data);
        if (track.color !== selectedColor) {
          dispatch(changeTrackColor(selectedColor));
        }
        context.closeModal(id);
      } catch (error) {
        console.error(error);
      }
    });
  };

  const handleConvertToRoutable = async (selectedRouting: Routing) => {
    try {
      const convertedTrack = await convertTrackToRoutable(
        selectedRouting,
        computedTrackpoints
      );
      if (!convertedTrack) throw new Error();
      routingDispatch([
        rewriteTrackWaypoints(convertedTrack),
        rewriteComputedTrackpoints([]),
        changeRoutingType(selectedRouting),
      ]);
      context.closeModal(id);
    } catch (error) {
      const msg = getErrorMsg(error);
      openErrorModal({
        title: t("errors.cannot_convert_track_to_routable"),
        text: msg ?? "",
      });
    }
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <Stack>
        <TextInput
          data-autofocus
          required
          label={t("track_edit_modal.options.name_label")}
          {...form.getInputProps("name")}
          data-lpignore="true"
          disabled={!!status}
        />
        <Textarea
          autosize
          minRows={3}
          maxRows={6}
          label={t("track_edit_modal.options.description_label")}
          {...form.getInputProps("desc")}
          disabled={!!status}
        />

        <LinksEditor
          target="track"
          onEdit={setLinks}
          links={links}
          disabled={!!status}
        />

        <ColorInput
          value={selectedColor}
          onChange={setSelectedColor}
          label={t("track_edit_modal.options.color_label")}
          placeholder={t("track_edit_modal.options.color_placeholder")}
          swatchesPerRow={6}
          format="hex"
          swatches={COLOR_SWATCHES}
          disabled={!!status}
          required
        />

        <Checkbox
          label={t("track_edit_modal.options.public_label")}
          {...form.getInputProps("pub", { type: "checkbox" })}
          disabled={!!status}
        />

        {track.routing === Routing.none ? (
          <Menu
            position="bottom-end"
            styles={{
              itemIcon: {
                minWidth: 18,
                display: "flex",
                flexDirection: "column",
              },
            }}
          >
            <Menu.Target>
              <Button
                disabled={!!status}
                rightIcon={<FontAwesomeIcon icon={faChevronDown} />}
                sx={{ placeSelf: "flex-start" }}
              >
                {t("track_edit_modal.convert_to_routable_button_label")}
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                onClick={() => handleConvertToRoutable(Routing.bicycle)}
                icon={<FontAwesomeIcon icon={faBicycle} color="gray" />}
              >
                {t("general.routing.bike")}
              </Menu.Item>
              <Menu.Item
                onClick={() => handleConvertToRoutable(Routing.auto)}
                icon={<FontAwesomeIcon icon={faCar} color="gray" />}
              >
                {t("general.routing.car")}
              </Menu.Item>
              <Menu.Item
                onClick={() => handleConvertToRoutable(Routing.pedestrian)}
                icon={<FontAwesomeIcon icon={faWalking} color="gray" />}
              >
                {t("general.routing.walk")}
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        ) : (
          <Button
            disabled={!!status}
            onClick={handleConvertToNonRoutable}
            sx={{ placeSelf: "flex-start" }}
          >
            {t("track_edit_modal.convert_to_nonroutable_button_label")}
          </Button>
        )}

        <Spoiler
          showLabel={t("edit_modals_common.show_advanced_fields")}
          maxHeight={0}
          hideLabel={t("edit_modals_common.hide_advanced_fields")}
        >
          <Stack mb="xs">
            <TextInput
              label={t("edit_modals_common.cmt_label")}
              {...form.getInputProps("cmt")}
            />
            <TextInput
              label={t("edit_modals_common.src_label")}
              {...form.getInputProps("src")}
            />
            <TextInput
              label={t("edit_modals_common.number_label")}
              {...form.getInputProps("number")}
            />
            <TextInput
              label={t("edit_modals_common.type_label")}
              {...form.getInputProps("type")}
            />
          </Stack>
        </Spoiler>

        <Divider />

        <Group spacing="xs">
          <Box style={{ flexGrow: 1 }}>
            <Button
              color="red"
              onClick={handleDelete}
              disabled={!!status}
              variant="filled"
            >
              {t("generic.delete_button_label")}
            </Button>
          </Box>
          <Button
            onClick={() => saveTrack({ saveAsNew: true })}
            disabled={!!status && status !== "duplicating"}
            loading={status === "duplicating"}
            variant="outline"
          >
            {t("generic.duplicate_button_label")}
          </Button>
          <Button
            type="submit"
            disabled={!!status && status !== "saving"}
            loading={status === "saving"}
            variant="filled"
          >
            {t("generic.save_button_label")}
          </Button>
        </Group>
      </Stack>
    </form>
  );
};
