export const TronLegendBody = () => (
  <tbody>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/motorway_z8.gif" width="70" />
      </th>
      <td>{'Motorway (lower zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/motorway.gif" width="70" />
      </th>
      <td>{'Motorway (higher zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/primary.gif" width="70" />
      </th>
      <td>{'Trunk road, primary road, secondary road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/primary_ref.gif" width="70" />
      </th>
      <td>{'Labelled primary road/trunk road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/tertiary.gif" width="70" />
      </th>
      <td>{'Tertiary road, minor road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/link.gif" width="70" />
      </th>
      <td>{'Slip road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/service.gif" width="70" />
      </th>
      <td>{'Service'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/motorway_tunnel_lower.gif" width="70" />
      </th>
      <td>{'Motorway with tunnel (lower zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/motorway_tunnel.gif" width="70" />
      </th>
      <td>{'Motorway with tunnel (higher zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/primary_tunnel.gif" width="70" />
      </th>
      <td>{'Trunk road, primary road, secondary road with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/tertiary_tunnel.gif" width="70" />
      </th>
      <td>{'Tertiary road with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/minor_road_tunnel.gif" width="70" />
      </th>
      <td>{'Minor road with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/footway.gif" width="70" />
      </th>
      <td>{'Footway, path, pedestrian street, cycleway, bridleway, track'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/steps.gif" width="70" />
      </th>
      <td>{'Steps'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/footway_tunnel.gif" width="70" />
      </th>
      <td>{'Footway, path, pedestrian street, cycleway, bridleway with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/runway_lower.gif" width="70" />
      </th>
      <td>{'Runway (lower zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/runway.gif" width="70" />
      </th>
      <td>{'Runway (higher zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/taxiway.gif" width="70" />
      </th>
      <td>{'Taxiway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/train.gif" width="70" />
      </th>
      <td>{'Railway - train'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/subway.gif" width="70" />
      </th>
      <td>{'Subway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/tram.gif" width="70" />
      </th>
      <td>{'Tram, light rail'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/pier.gif" width="70" />
      </th>
      <td>{'Pier'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/platform.gif" />
      </th>
      <td>{'Platform'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/boundary_country.gif" />
      </th>
      <td>{'Country boundary'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/boundary_regional.gif" />
      </th>
      <td>{'Regional boundary'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/water.gif" width="60" />
      </th>
      <td>{'Ocean, sea, lake (lower zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/water_middle.gif" width="60" />
      </th>
      <td>{'Ocean, sea, lake (middle zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/water_higher.gif" width="60" />
      </th>
      <td>{'Ocean, sea (higher zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/lake.gif" width="62" />
      </th>
      <td>{'Lake (higher zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/swimming_pool.gif" width="69" />
      </th>
      <td>{'Swimming pool'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/forest.gif" width="60" />
      </th>
      <td>{'Forest'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/nature_reserve.gif" width="60" />
      </th>
      <td>{'Nature reserve'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/protected_area.gif" width="60" />
      </th>
      <td>{'National park, protected area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/farmland.gif" width="60" />
      </th>
      <td>{'Farmland'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/park.gif" width="60" />
      </th>
      <td>{'Park'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/golf_course.gif" width="60" />
      </th>
      <td>{'Golf course'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/hospital.gif" width="60" />
      </th>
      <td>{'Hospital'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/university.gif" width="60" />
      </th>
      <td>{'University, college'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/parking.gif" width="60" />
      </th>
      <td>{'Parking'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/substation.gif" width="60" />
      </th>
      <td>{'Substation, plant, generator'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/man_made.gif" width="60" />
      </th>
      <td>{'Wastewater plant, works, waterworks, breakwater, pier area, bridge area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/pedestrian.gif" width="60" />
      </th>
      <td>{'Pedestrian area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/river.gif" width="70" />
      </th>
      <td>{'River'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Natural features'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/peak.gif" width="18" />
      </th>
      <td>{'Peak'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/volcano.gif" width="18" />
      </th>
      <td>{'Volcano'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/spring.gif" width="18" />
      </th>
      <td>{'Spring, hot spring'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/beach.gif" width="18" />
      </th>
      <td>{'Beach'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Civil services, education, worship'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/town_hall.gif" width="20" />
      </th>
      <td>{'Town hall, government offices'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/police.gif" width="20" />
      </th>
      <td>{'Police'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/fire_station.gif" width="20" />
      </th>
      <td>{'Fire station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/court.gif" width="20" />
      </th>
      <td>{'Court'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/post_office.gif" width="20" />
      </th>
      <td>{'Post office'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/bank.gif" width="18" />
      </th>
      <td>{'Bank'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/atm.gif" width="18" />
      </th>
      <td>{'ATM'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/car_sharing.gif" width="18" />
      </th>
      <td>{'Car sharing'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/bicycle_rental.gif" width="18" />
      </th>
      <td>{'Bicycle rental, bike shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/university_icon.gif" width="18" />
      </th>
      <td>{'College, university'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/kindergarten.gif" width="18" />
      </th>
      <td>{'Kindergarten, school'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/library.gif" width="18" />
      </th>
      <td>{'Library'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/vet.gif" width="18" />
      </th>
      <td>{'Vet, pet shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/place_of_worship.gif" width="18" />
      </th>
      <td>{'Place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/place_of_worship_christian.gif" width="18" />
      </th>
      <td>{'Christian place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/place_of_worship_jewish.gif" width="18" />
      </th>
      <td>{'Jewish place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/place_of_worship_muslim.gif" width="18" />
      </th>
      <td>{'Muslim place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/place_of_worship_buddhist.gif" width="18" />
      </th>
      <td>{'Buddhist place of worship'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Healthcare, social services'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/pharmacy.gif" width="18" />
      </th>
      <td>{'Pharmacy'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/hospital_icon.gif" width="18" />
      </th>
      <td>{'Hospital'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/nursing_home.gif" width="18" />
      </th>
      <td>{'Nursing home'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Accomodation, shelters'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/hotel.gif" width="18" />
      </th>
      <td>{'Hotel, hostel, motel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/camping.gif" width="18" />
      </th>
      <td>{'Camping, summer camp'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Culture, entertainment, leisure, sport, tourism'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/museum.gif" width="18" />
      </th>
      <td>{'Museum, observatory'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/theatre.gif" width="18" />
      </th>
      <td>{'Theatre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/cinema.gif" width="18" />
      </th>
      <td>{'Cinema'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/gallery.gif" width="18" />
      </th>
      <td>{'Gallery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/national_park_icon.gif" width="18" />
      </th>
      <td>{'National park, protected area, park, battlefield'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/national_park_icon_z13.gif" width="32" />
      </th>
      <td>{'National park, battlefield (up zoom 13)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/forest_icon.gif" width="18" />
      </th>
      <td>{'Nature reserve, forest'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/garden.gif" width="18" />
      </th>
      <td>{'Garden'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/playground.gif" width="18" />
      </th>
      <td>{'Playground'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/recreation_ground.gif" width="18" />
      </th>
      <td>{'Recreation ground, sports centre, outdoor gym, fitness centre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/pitch.gif" width="18" />
      </th>
      <td>{'Pitch'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/golf_course_icon.gif" width="18" />
      </th>
      <td>{'Golf course'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/pitch_soccer.gif" width="18" />
      </th>
      <td>{'Football field'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/pitch_tennis.gif" width="18" />
      </th>
      <td>{'Tennis court'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/pitch_basketball.gif" width="18" />
      </th>
      <td>{'Basketball court'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/pitch_baseball.gif" width="18" />
      </th>
      <td>{'Baseball field'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/water_park.gif" width="18" />
      </th>
      <td>{'Water park'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/viewpoint.gif" width="18" />
      </th>
      <td>{'Viewpoint'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/information.gif" width="6" />
      </th>
      <td>{'Tourist info'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/fort.gif" width="18" />
      </th>
      <td>{'Fort'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/monument.gif" width="18" />
      </th>
      <td>{'Memorial, monument'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/archaeological_site.gif" width="18" />
      </th>
      <td>{'Archaeological site'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/stadium.gif" width="18" />
      </th>
      <td>{'Stadium'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/winter_sports.gif" width="18" />
      </th>
      <td>{'Winter sports centre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/marina.gif" width="18" />
      </th>
      <td>{'Marina'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/aquarium.gif" width="18" />
      </th>
      <td>{'Aquarium'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/zoo.gif" width="18" />
      </th>
      <td>{'Zoo'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/theme_park.gif" width="18" />
      </th>
      <td>{'Theme park, amusement ride'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/slipway.gif" width="18" />
      </th>
      <td>{'Slipway'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Transport'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/parking_icon.gif" width="13" />
      </th>
      <td>{'Parking'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/bicycle_parking.gif" width="13" />
      </th>
      <td>{'Bicycle parking'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/bus_station.gif" width="16" />
      </th>
      <td>{'Bus station, bus stop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/train_station.gif" width="18" />
      </th>
      <td>{'Train station, tram stop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/train_station_z18.gif" width="32" />
      </th>
      <td>{'Train station (up zoom 18)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/fuel.gif" width="18" />
      </th>
      <td>{'Petrol station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/traffic_lights.gif" width="19" />
      </th>
      <td>{'Traffic lights'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/airport.gif" width="18" />
      </th>
      <td>{'Airport'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/airport_z12.gif" width="18" />
      </th>
      <td>{'Airport (up zoom 12)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/ferry_terminal.gif" width="18" />
      </th>
      <td>{'Ferry terminal'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/subway_entrance.gif" width="14" />
      </th>
      <td>{'Subway entrance'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Offices'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/office.gif" width="18" />
      </th>
      <td>{'Company, insurance, office'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/estate_agent.gif" width="18" />
      </th>
      <td>{'Estate agent'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Food & Drink'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/bar.gif" width="18" />
      </th>
      <td>{'Bar, pub'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/cafe.gif" width="18" />
      </th>
      <td>{'Cafe'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/biergarten.gif" width="18" />
      </th>
      <td>{'Beer garden, brewery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/fast_food.gif" width="18" />
      </th>
      <td>{'Fast food'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/restaturant.gif" width="18" />
      </th>
      <td>{'Restaturant'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/ice_cream.gif" width="18" />
      </th>
      <td>{'Ice cream'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Craft'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/photographer.gif" width="18" />
      </th>
      <td>{'Photographer, photographic laboratory'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/confectionery.gif" width="18" />
      </th>
      <td>{'Confectionery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/winery.gif" width="18" />
      </th>
      <td>{'Winery, wine shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/tailor.gif" width="18" />
      </th>
      <td>{'Tailor'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/shoemaker.gif" width="18" />
      </th>
      <td>{'Shoemaker'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Shopping'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/alcohol.gif" width="18" />
      </th>
      <td>{'Alcohol shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/bakery.gif" width="18" />
      </th>
      <td>{'Bakery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/books.gif" width="18" />
      </th>
      <td>{'Bookshop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/butcher.gif" width="18" />
      </th>
      <td>{'Butcher'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/car_repair.gif" width="18" />
      </th>
      <td>{'Car repair'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/car.gif" width="18" />
      </th>
      <td>{'Car shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/clothes.gif" width="18" />
      </th>
      <td>{'Clothes shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/computer.gif" width="18" />
      </th>
      <td>{'Computer store, Electronics shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/convenience.gif" width="18" />
      </th>
      <td>{'Convenience store'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/department_store.gif" width="18" />
      </th>
      <td>{'Department store, mall'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/diy.gif" width="18" />
      </th>
      <td>{'Do-it-yourself store, hardware store, painter'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/dry_cleaner.gif" width="18" />
      </th>
      <td>{'Dry-cleaner'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/florist.gif" width="18" />
      </th>
      <td>{'Florist'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/gift.gif" width="18" />
      </th>
      <td>{'Gift shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/greengrocer.gif" width="18" />
      </th>
      <td>{'Greengrocery, supermarket'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/hairdresser.gif" width="18" />
      </th>
      <td>{'Hairdresser'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/jewellery.gif" width="18" />
      </th>
      <td>{'Jewellery shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/laundry.gif" width="18" />
      </th>
      <td>{'Laundry'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/mobile_phone.gif" width="18" />
      </th>
      <td>{'Mobile phone shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/music.gif" width="18" />
      </th>
      <td>{'Music shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/newspaper.gif" width="18" />
      </th>
      <td>{'Newspaper'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/optician.gif" width="18" />
      </th>
      <td>{'Optician'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/sports.gif" width="20" />
      </th>
      <td>{'Sport shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/toys.gif" width="18" />
      </th>
      <td>{'Toy shop'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Other symbols'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/toilets.gif" width="18" />
      </th>
      <td>{'Toilets'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/drinking_water.gif" width="18" />
      </th>
      <td>{'Drinking water'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/recycling.gif" width="18" />
      </th>
      <td>{'Recycling'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/bench.gif" width="18" />
      </th>
      <td>{'Bench'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/lighthouse.gif" width="18" />
      </th>
      <td>{'Lighthouse'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/generator.gif" width="18" />
      </th>
      <td>{'Generator, plant, substation, wastewater plant, waterworks, works'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/cemetery.gif" width="18" />
      </th>
      <td>{'Cemetery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/quarry.gif" width="18" />
      </th>
      <td>{'Quarry, mineshaft, adit'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/generic.gif" width="14" />
      </th>
      <td>{'Generic point of interest'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Bike trails'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/bike_trail.gif" width="70" />
      </th>
      <td>{'Cycleway'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Hike waymarked'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/hike_red.gif" width="70" />
      </th>
      <td>{'Red hiking route'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/hike_blue.gif" width="70" />
      </th>
      <td>{'Blue hiking route'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/tron/images/legend/hike_green.gif" width="70" />
      </th>
      <td>{'Green hiking route'}</td>
    </tr>
  </tbody>
)
