import { ReactElement } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  faHeartbeat,
  faGaugeHigh,
  faArrowRotateRight,
  faTemperatureThreeQuarters,
  faBolt,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ElevationIcon } from 'assets/icons/ElevationIcon'
import { TFunction } from 'i18next'
import { ChartYAxisType } from 'types/app'

export interface SelectOption {
  value: ChartYAxisType
  label: string
  icon: ReactElement
}

// Use this getter to add more items to the chart panel y axis select box
export const getYAxisOptions = (t: TFunction, picks?: ChartYAxisType[]): SelectOption[] => {
  const getIcon = (icon: IconProp) => <FontAwesomeIcon icon={icon} />
  const data: SelectOption[] = [
    { value: 'elevation', label: t('y_axis_options.elevation'), icon: <ElevationIcon /> },
    { value: 'speed', label: t('y_axis_options.speed'), icon: getIcon(faGaugeHigh) },
    { value: 'cadence', label: t('y_axis_options.cadence'), icon: getIcon(faArrowRotateRight) },
    { value: 'heartRate', label: t('y_axis_options.heart_rate'), icon: getIcon(faHeartbeat) },
    { value: 'power', label: t('y_axis_options.power'), icon: getIcon(faBolt) },
    { value: 'temperature', label: t('y_axis_options.temperature'), icon: getIcon(faTemperatureThreeQuarters) },
  ]
  if (picks) {
    return data.filter((datapoint) => picks.includes(datapoint.value))
  }
  return data
}
