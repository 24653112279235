import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { ColorScheme } from "@mantine/core";
import axios, { AxiosResponse } from "axios";
import { jwtDecode } from "jwt-decode";
import { useOptions } from "stores/optionsStore/OptionsContext";
import { useRouting } from "stores/routingStore/RoutingContext";
import { User } from "types/app";
import { uninterceptedAxiosInstance } from "utils/axios/axios";
import { getErrorMsg } from "utils/getErrorMsg/getErrorMsg";
import {
  parseSettingsToObj,
  parseSettingsToString,
  UserSettings,
} from "utils/helpers";
import { showErrorNotification } from "utils/notifications/customNotifications";

import { useLocalUserData } from "./useLocalUserData";
import { useTranslation } from "react-i18next";

const BASE_API_URL = import.meta.env.VITE_BASE_API_URL ?? "";

type JwtToken = {
  id: number;
  iat: number;
};

type SignInReturn = {
  user: Omit<User, "id"> | null;
};

type SignUpReturn = {
  success: string | null;
  errors: Record<string, string> | null;
  error?: string | null;
};

export class UpdateUserMultiError extends Error {
  errorCodes: string[];

  constructor(errorCodes: string[]) {
    super();
    this.errorCodes = errorCodes;
  }
}

export interface Auth {
  user: User | null;
  signIn: (email: string, password: string) => Promise<SignInReturn>;
  update: (settings: Partial<UserSettings>) => Promise<void>;
  signOut: () => Promise<void>;
  signUp: (
    email: string,
    password: string,
    passwordrepeat: string,
    receiveNewsletter: boolean,
    colorScheme?: ColorScheme
  ) => Promise<SignUpReturn>;
  resetPassword: (
    email: string
  ) => Promise<{ success: string | null; error: string | null }>;
  setPassword: (
    password: string,
    passwordRepeat: string,
    token: string
  ) => Promise<{ success: boolean | null; error: string | null }>;
  loading: boolean;
  deleteUser: () => Promise<unknown>;
  getAppSubscriptionStatus: (language: string) => Promise<
    AxiosResponse<
      {
        errors: string[];
        state: "active" | "inGracePeriod" | "inactive";
      },
      unknown
    >
  >;
  getUserSettings: () => Promise<UserSettings>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const AuthContext = React.createContext<Auth>({
  user: null,
  loading: false,
} as Auth);

export const useProvideAuth = (): Auth => {
  const {
    setLocalUser,
    localUser,
    removeLocalUser: removeUser,
  } = useLocalUserData();
  const [user, setUser] = useState<User | null>(localUser);
  const [loading, setLoading] = useState(false);
  const {
    state: { language },
  } = useOptions();
  const { state, changeRouting } = useRouting();
  const {i18n} = useTranslation();
  const initialized = useRef(false);

  const currentTrackWaypointsCount = state?.trackWaypoints?.length;

  useEffect(() => {
    if (
      user?.settings?.routing &&
      currentTrackWaypointsCount === 0 &&
      !initialized.current
    ) {
      initialized.current = true;
      changeRouting(user.settings.routing);
    }
  }, [currentTrackWaypointsCount, changeRouting, user]);

  // Update Settings store with data from localstorage when user visits page
  useEffect(() => {
    if (localUser) {
      setUser(localUser);
    }
  }, []);

  const signUp = async (
    email: string,
    password: string,
    passwordrepeat: string,
    receiveNewsletter: boolean,
    colorScheme?: ColorScheme
  ) => {
    try {
      setLoading(true);
      const { data } = await uninterceptedAxiosInstance.post(
        `${BASE_API_URL}/register`,
        {
          email,
          password,
          passwordrepeat,
          receiveNewsletter,
          colorScheme,
          agent: "Trackbook",
          lng: language,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // This is not the cleanest way how to handle errors that shouldn't block
      // user registration flow but at the time it was the path of least resistance
      if (Array.isArray(data?.internal_errors)) {
        const errors = data.internal_errors as { msg: string; err: string }[];

        errors.forEach((error) => {
          showErrorNotification({
            autoClose: 7000,
            message: error.msg,
          });
        });
      }

      return { success: data.msg, errors: null, error: null };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errors = error?.response?.data;
        if (error.response?.status === 409) {
          return {
            success: null,
            errors: {
              email: errors?.msg_email,
              password: errors?.msg_password,
              passwordrepeat: errors?.msg_password_repeat,
            },
            error: null,
          };
        }
      }
      return { success: null, errors: null, error: getErrorMsg(error) };
    } finally {
      setUser(null);
      setLoading(false);
    }
  };

  // Loader is set externally for this call
  const signIn = async (email: string, password: string) => {
    try {
      const res = await uninterceptedAxiosInstance.post(
        `${BASE_API_URL}/login`,
        {
          email,
          password,
          agent: "Trackbook",
          lng: language,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      const token = res?.data?.token;
      const settingsString = res?.data?.settings;
      let settings = null;
      try {
        settings = parseSettingsToObj(settingsString);
      } catch (error) {
        console.error(error);
      }
      const tokenData: JwtToken = jwtDecode(token);
      const signedInUser: User = { email, settings, id: tokenData.id };

      setUser(signedInUser);
      setLocalUser(signedInUser);
      return { user: signedInUser };
    } catch (error) {
      setUser(null);
      throw error;
    }
  };

  const update = useCallback(
    async (settings: Partial<UserSettings>) => {
      if (!user) throw new Error("user_not_signed_in");
      setLoading(true);
      const mergedSettings = Object.assign(user.settings ?? {}, settings);
      setLocalUser({ ...user, settings: mergedSettings });
      const settingsAsString = parseSettingsToString(mergedSettings);
      try {
        const { data } = await axios.post(
          `${BASE_API_URL}/update-user`,
          {
            agent: "Trackbook",
            lng: language,
            settings: settingsAsString,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );

        if (Array.isArray(data?.internal_errors)) {
          const errors = data.internal_errors as { msg: string; err: string }[];

          errors.forEach((error) => {
            showErrorNotification({
              autoClose: 7000,
              message: error.msg,
            });
          });

          throw new UpdateUserMultiError(errors.map((error) => error.err));
        }
      } catch (error) {
        if (error instanceof UpdateUserMultiError) {
          // We just propagate the error here
          throw error;
        } else {
          showErrorNotification({
            autoClose: 7000,
            message: axios.isAxiosError(error)
              ? error.response?.data?.msg
              : getErrorMsg(error),
          });

          throw new Error("generic_update_failed");
        }
      } finally {
        setLoading(false);
      }
    },
    [language, setLocalUser, user]
  );

  const signOut = useCallback(async () => {
    removeUser();
    setUser(null);
    // calling logout endpoint will delete the httponly cookie
    try {
      await uninterceptedAxiosInstance.get(`${BASE_API_URL}/logout`, {
        withCredentials: true,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 401) {
          console.warn("The cookie was already deleted");
        }
      }
    }
  }, [removeUser]);

  const setPassword = async (
    password: string,
    passwordRepeat: string,
    token: string
  ) => {
    try {
      setLoading(true);
      const res = await uninterceptedAxiosInstance.post(
        `${BASE_API_URL}/set-password`,
        {
          passwordreset: token,
          password,
          passwordrepeat: passwordRepeat,
          agent: "Trackbook",
          lng: language,
        }
      );
      return { success: res.data?.msg, error: null };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return { success: null, error: error.response?.data?.msg };
      }
      return { success: null, error: getErrorMsg(error) };
    } finally {
      setLoading(false);
    }
  };

  const resetPassword = async (email: string) => {
    try {
      setLoading(true);
      const res = await uninterceptedAxiosInstance.post(
        `${BASE_API_URL}/password-reset`,
        {
          email,
          agent: "Trackbook",
          lng: language,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return { success: res.data?.msg, error: null };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return { success: null, error: error.response?.data?.msg };
      }
      return { success: null, error: getErrorMsg(error) };
    } finally {
      setLoading(false);
    }
  };

  const deleteUser = useCallback(async () => {
    if (!user) throw new Error("user_not_signed_in");
    setLoading(true);
    const { data } = await axios.post(
      `${BASE_API_URL}/delete-user`,
      {
        agent: "Trackbook",
        lng: language,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    signOut();
    setLoading(false);
    return data;
  }, [language, signOut, user]);

  const getAppSubscriptionStatus = useCallback(async (language: string) => {
    return uninterceptedAxiosInstance.post<{
      errors: string[];
      state: "active" | "inGracePeriod" | "inactive";
    }>(
      `${BASE_API_URL}/get-app-subscription-status`,
      {
        agent: "Trackbook",
        lng: language,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
  }, []);

  const getUserSettings = useCallback(async () => {
    const {data} = await uninterceptedAxiosInstance.post<{
      settings: string;
    }>(
      `${BASE_API_URL}/get-user-settings`,
      {
        agent: "Trackbook",
        lng: language,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    
    const settings = JSON.parse(data.settings) as UserSettings;

    if (settings?.language) {
      i18n.changeLanguage(settings?.language);
      localStorage.setItem("language", settings?.language);
    }

    return settings;
  }, []);

  return {
    user,
    signIn,
    update,
    setLoading,
    signOut,
    signUp,
    resetPassword,
    loading,
    deleteUser,
    setPassword,
    getAppSubscriptionStatus,
    getUserSettings,
  };
};

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({
  children,
  onAuth,
}: {
  children: ReactNode;
  onAuth?: (authInstance: Auth) => void;
}) => {
  const auth = useProvideAuth();
  useEffect(() => {
    if (onAuth) {
      onAuth(auth);
    }
  }, [auth, onAuth]);

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
