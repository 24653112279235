import {
  Image,
  List,
  Stack,
  Tabs,
  TypographyStylesProvider,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { faAndroid, faApple } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { subcriptionAndroid, subcriptionIOS } from "assets/gpxViewerAppsModal";

import "./GPXViewerAppsModal.css";

export const GPXViewerAppsModal = () => {
  const { t } = useTranslation();

  return (
    <>
      <TypographyStylesProvider>
        <Stack p="sm">
          <div className="trkbk-md-paragraph">
            <ReactMarkdown skipHtml>
              {t("gpx_viewer_apps_modal.intro_paragraph")}
            </ReactMarkdown>
          </div>
          <Tabs defaultValue="googlePlay">
            <Tabs.List grow position="center">
              <Tabs.Tab
                value="googlePlay"
                icon={<FontAwesomeIcon icon={faAndroid} />}
              >
                Android
              </Tabs.Tab>
              <Tabs.Tab
                value="appStore"
                icon={<FontAwesomeIcon icon={faApple} />}
              >
                iOS
              </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="googlePlay" pt="xs">
              <div className="trkbk-tabs-panel-container">
                <div className="trkbk-paragraph-column left">
                  <div className="trkbk-bottom-margin">
                    <Image src={subcriptionAndroid} />
                  </div>
                </div>
                <div className="trkbk-paragraph-column">
                  <List spacing="sm">
                    <List.Item className="trkbk-premium-modal-list-item">
                      <ReactMarkdown skipHtml>
                        {t("gpx_viewer_apps_modal.google_step_1")}
                      </ReactMarkdown>
                    </List.Item>
                    <List.Item className="trkbk-premium-modal-list-item">
                      <ReactMarkdown skipHtml>
                        {t("gpx_viewer_apps_modal.google_step_2")}
                      </ReactMarkdown>
                    </List.Item>
                    <List.Item className="trkbk-premium-modal-list-item">
                      <ReactMarkdown skipHtml>
                        {t("gpx_viewer_apps_modal.google_step_3")}
                      </ReactMarkdown>
                    </List.Item>
                    <List.Item className="trkbk-premium-modal-list-item">
                      <ReactMarkdown skipHtml>
                        {t("gpx_viewer_apps_modal.google_step_4")}
                      </ReactMarkdown>
                    </List.Item>
                    <List.Item className="trkbk-premium-modal-list-item">
                      <ReactMarkdown skipHtml>
                        {t("gpx_viewer_apps_modal.google_step_5")}
                      </ReactMarkdown>
                    </List.Item>
                  </List>
                </div>
              </div>
            </Tabs.Panel>
            <Tabs.Panel value="appStore" pt="xs">
              <div className="trkbk-tabs-panel-container">
                <div className="trkbk-paragraph-column left">
                  <div className="trkbk-bottom-margin">
                    <Image src={subcriptionIOS} />
                  </div>
                </div>
                <div className="trkbk-paragraph-column">
                  <List spacing="sm">
                    <List.Item className="trkbk-premium-modal-list-item">
                      <ReactMarkdown skipHtml>
                        {t("gpx_viewer_apps_modal.apple_step_1")}
                      </ReactMarkdown>
                    </List.Item>
                    <List.Item className="trkbk-premium-modal-list-item">
                      <ReactMarkdown skipHtml>
                        {t("gpx_viewer_apps_modal.apple_step_2")}
                      </ReactMarkdown>
                    </List.Item>
                    <List.Item className="trkbk-premium-modal-list-item">
                      <ReactMarkdown skipHtml>
                        {t("gpx_viewer_apps_modal.apple_step_3")}
                      </ReactMarkdown>
                    </List.Item>
                    <List.Item className="trkbk-premium-modal-list-item">
                      <ReactMarkdown skipHtml>
                        {t("gpx_viewer_apps_modal.apple_step_4")}
                      </ReactMarkdown>
                    </List.Item>
                  </List>
                </div>
              </div>
            </Tabs.Panel>
          </Tabs>
          <div className="trkbk-bottom-offset-medium"></div>
        </Stack>
      </TypographyStylesProvider>
    </>
  );
};
