import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";
import * as Sentry from "@sentry/react";
import { BrowserRouter } from "react-router-dom";
import { App } from "./App";
import { LoadingScreen } from "./LoadingScreen";

import "./index.css";
import "./utils/i18n/i18n";

Sentry.init({
  dsn: "https://3dc0a742deb030e11a480a22e4d419e3@o4508218204749824.ingest.de.sentry.io/4508218206715984",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/trackbook\.online/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const BASE_API_URL = import.meta.env.VITE_BASE_API_URL;

const instance = createInstance({
  urlBase: BASE_API_URL,
  siteId: 3,
  trackerUrl: "https://analytics.vecturagames.com/matomo.php",
  srcUrl: "https://analytics.vecturagames.com/matomo.js",
  linkTracking: false,
  configurations: {
    disableCookies: true,
  },
});

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <Suspense fallback={<LoadingScreen />}>
      <BrowserRouter>
        {/* @ts-expect-error The types in the Matomo lib are not done properly and the lib is not maintained anymore */}
        <MatomoProvider value={instance}>
          <App />
        </MatomoProvider>
      </BrowserRouter>
    </Suspense>
  </React.StrictMode>
);
