export const BasicLegendBody = () => (
  <tbody>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/motorway.gif" />
      </th>
      <td>{'Motorway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/major_road.gif" />
      </th>
      <td>{'Major road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/minor_road.gif" />
      </th>
      <td>{'Minor road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/motorway_tunnel.gif" />
      </th>
      <td>{'Motorway with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/major_road_tunnel.gif" />
      </th>
      <td>{'Major road with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/minor_road_tunnel.gif" />
      </th>
      <td>{'Minor road with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/oneway.gif" width="70" />
      </th>
      <td>{'Oneway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/path.gif" width="70" />
      </th>
      <td>{'Footway, path, cycleway, bridleway, track, steps'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/runway.gif" width="70" />
      </th>
      <td>{'Runway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/railway.gif" width="70" />
      </th>
      <td>{'Railway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/railway_tunnel.gif" width="70" />
      </th>
      <td>{'Railway with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/aerialway.gif" />
      </th>
      <td>
        {
          'Chairlift, gondola lift, cable car, goods cable lift, mixed lift, drag lift, t-bar lift, j-bar lift, platter lift, magic carpet, rope tow, zipline'
        }
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/ferry.gif" width="70" />
      </th>
      <td>{'Ferry route'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/boundary_country.gif" width="62" height="32" />
      </th>
      <td>{'Country boundary'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/boundary_regional.gif" width="61" height="31" />
      </th>
      <td>{'Regional boundary'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/military.gif" width="61" height="31" />
      </th>
      <td>{'Military area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/water.gif" />
      </th>
      <td>{'Ocean, sea, lake'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/park.gif" />
      </th>
      <td>{'Forest, park, national park, protected area, nature reserve, cemetery, recreation ground'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/pitch.gif" />
      </th>
      <td>{'Natural wood, grass, garden, allotments, farmland, pitch, playground, golf course, place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/urban.gif" />
      </th>
      <td>
        {
          'Stadium, hospital, university, school, urban area, retail area, commercial area, industrial area, pier area, bridge area'
        }
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/peak.gif" width="16" />
      </th>
      <td>{'Peak, volcano'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/police.gif" width="16" />
      </th>
      <td>{'Police'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/bank.gif" width="13" />
      </th>
      <td>{'Bank'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/atm.gif" width="16" />
      </th>
      <td>{'ATM'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/car_sharing.gif" width="16" />
      </th>
      <td>{'Car sharing'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/bicycle_rental.gif" width="16" />
      </th>
      <td>{'Bicycle rental'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/university.gif" width="16" />
      </th>
      <td>{'College, university'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/kindergarten.gif" width="16" />
      </th>
      <td>{'Kindergarten, school'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/chapel.gif" width="16" />
      </th>
      <td>{'Church, chapel, place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/pharmacy.gif" width="16" />
      </th>
      <td>{'Pharmacy'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/hospital.gif" width="15" />
      </th>
      <td>{'Hospital'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/workshop.gif" width="16" />
      </th>
      <td>{'Workshop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/hotel.gif" width="16" />
      </th>
      <td>{'Hotel, hostel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/theatre.gif" width="16" />
      </th>
      <td>{'Theatre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/cinema.gif" width="16" />
      </th>
      <td>{'Cinema'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/forest.gif" width="16" />
      </th>
      <td>{'Forest, nature reserve'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/park_icon.gif" width="16" />
      </th>
      <td>{'Park'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/sports_centre.gif" width="16" />
      </th>
      <td>{'Sports centre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/winter_sports.gif" width="16" />
      </th>
      <td>{'Winter sports centre'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/viewpoint.gif" width="16" />
      </th>
      <td>{'Viewpoint'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/information.gif" width="12" />
      </th>
      <td>{'Tourist info'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/parking.gif" width="13" />
      </th>
      <td>{'Parking'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/bicycle_parking.gif" width="16" />
      </th>
      <td>{'Bicycle parking'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/bus_stop.gif" width="16" />
      </th>
      <td>{'Bus stop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/train.gif" width="16" />
      </th>
      <td>{'Train station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/tram_stop.gif" width="16" />
      </th>
      <td>{'Tram stop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/fuel.gif" width="16" />
      </th>
      <td>{'Petrol station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/traffic_signals.gif" width="16" height="7" />
      </th>
      <td>{'Traffic lights'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/ferry_terminal.gif" width="16" />
      </th>
      <td>{'Ferry terminal'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/subway_entrance.gif" width="16" />
      </th>
      <td>{'Subway entrance'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/bar.gif" width="16" />
      </th>
      <td>{'Bar, pub, beer garden'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/cafe.gif" width="16" />
      </th>
      <td>{'Cafe'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/fast_food.gif" width="16" />
      </th>
      <td>{'Fast food'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/restaurant.gif" width="16" />
      </th>
      <td>{'Restaurant'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/bicycle.gif" width="16" />
      </th>
      <td>{'Bicycle shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/convenience.gif" width="16" />
      </th>
      <td>{'Convenience shop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/mall.gif" width="16" />
      </th>
      <td>{'Mall'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/toilet.gif" width="16" />
      </th>
      <td>{'Toilets'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/drinking_water.gif" width="16" />
      </th>
      <td>{'Drinking water'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/military_icon.gif" width="16" />
      </th>
      <td>{'Military area'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Bike trails'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/bike_trail.gif" width="70" />
      </th>
      <td>{'Cycleway'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Hike waymarked'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/hike_red.gif" width="70" />
      </th>
      <td>{'Red hiking route'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/hike_blue.gif" width="70" />
      </th>
      <td>{'Blue hiking route'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/basic/images/legend/hike_green.gif" width="70" />
      </th>
      <td>{'Green hiking route'}</td>
    </tr>
  </tbody>
)
