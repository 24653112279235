export const TopoLegendBody = () => (
  <tbody>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/motorway.gif" width="70" />
      </th>
      <td>{'Motorway, primary road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/major_road.gif" width="70" />
      </th>
      <td>{'Secondary road, tertiary road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/minor_road.gif" width="70" />
      </th>
      <td>{'Minor road'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/service.gif" width="70" />
      </th>
      <td>{'Service'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/pedestrian.gif" width="70" />
      </th>
      <td>{'Pedestrian street'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/raceway.gif" height="5.5" width="70" />
      </th>
      <td>{'Raceway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/oneway.gif" height="9" width="70" />
      </th>
      <td>{'Oneway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/footway.gif" width="70" />
      </th>
      <td>{'Footway, path, bridleway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/cycleway.gif" width="70" />
      </th>
      <td>{'Cycleway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/track_gr_2.gif" width="70" />
      </th>
      <td>{'Track (without grade)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/track_gr_1.gif" width="70" />
      </th>
      <td>{'Track (grade 1)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/track_gr_2.gif" width="70" />
      </th>
      <td>{'Track (grade 2)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/track_gr_3-4.gif" width="70" />
      </th>
      <td>{'Track (grade 3)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/track_gr_3-4.gif" width="70" />
      </th>
      <td>{'Track (grade 4)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/track_gr_5.gif" width="70" />
      </th>
      <td>{'Track (grade 5)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/steps.gif" width="70" />
      </th>
      <td>{'Steps'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/casing_tunnel.gif" width="70" />
      </th>
      <td>{'Dashed casing = tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/casing_bridge.gif" width="70" />
      </th>
      <td>{'Black casing = bridge'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/aeroway.gif" height="7" width="70" />
      </th>
      <td>{'Runway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/aeroway.gif" height="4" width="70" />
      </th>
      <td>{'Taxiway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/railway_tram.gif" width="70" />
      </th>
      <td>{'Tram'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/railway.gif" width="70" />
      </th>
      <td>{'Railway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/railway_tunnel.gif" width="70" />
      </th>
      <td>{'Railway with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/railway_disused.gif" width="70" />
      </th>
      <td>{'Disused railway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/railway_service.gif" width="70" />
      </th>
      <td>{'Siding, spur, yard, crossover'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/railway_funicular.gif" width="70" />
      </th>
      <td>{'Subway, funicular, narrow gauge, light rail, miniature railway'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/railway_monorail.gif" width="70" />
      </th>
      <td>{'Monorail'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/aerialway.gif" height="6" width="70" />
      </th>
      <td>
        {'Chairlift, drag lift, t-bar lift, j-bar lift, platter lift, rope tow, magic carpet, zipline, mixed lift'}
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/gondola.gif" width="70" />
      </th>
      <td>{'Cable car, gondola lift'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/goods.gif" width="70" />
      </th>
      <td>{'Goods cable lift'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/ferry.gif" width="70" />
      </th>
      <td>{'Ferry route'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/boundary_country_lower.gif" />
      </th>
      <td>{'Country boundary (lower zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/boundary_country_middle.gif" />
      </th>
      <td>{'Country boundary (middle zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/boundary_country_higher.gif" />
      </th>
      <td>{'Country boundary (higher zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/boundary_regional_lower.gif" />
      </th>
      <td>{'Regional boundary (lower zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/boundary_regional_higher.gif" />
      </th>
      <td>{'Regional boundary (higher zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/protected_area.gif" />
      </th>
      <td>{'National park, protected area, nature reserve boundary'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/military.gif" />
      </th>
      <td>{'Military area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/water.gif" />
      </th>
      <td>{'Ocean, sea, lake'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/glacier.gif" />
      </th>
      <td>{'Glacier'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/lake_intermittent.gif" />
      </th>
      <td>{'Intermittent lake'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/forest_deciduous.gif" />
      </th>
      <td>{'Broadleaved forest'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/forest_coniferous.gif" />
      </th>
      <td>{'Needleleaved forest'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/forest.gif" />
      </th>
      <td>{'Mixed forest'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/bare_rock.gif" />
      </th>
      <td>{'Bare rock'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/scree.gif" />
      </th>
      <td>{'Scree'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/scrub.gif" />
      </th>
      <td>{'Scrub'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/wetland.gif" />
      </th>
      <td>{'Wetland'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/reedbed.gif" />
      </th>
      <td>{'Reed bed'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/bog.gif" />
      </th>
      <td>{'Bog'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/swamp.gif" />
      </th>
      <td>{'Swamp'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/tidalflat_z9.gif" />
      </th>
      <td>{'Tidal flat (lower zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/tidalflat.gif" />
      </th>
      <td>{'Tidal flat (higher zooms)'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/sand.gif" />
      </th>
      <td>{'Beach, sand'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/meadow.gif" />
      </th>
      <td>{'Grass, meadow'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/vineyard.gif" />
      </th>
      <td>{'Vineyard'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/orchard.gif" />
      </th>
      <td>{'Orchard'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/allotments.gif" />
      </th>
      <td>{'Allotments'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/grave_yard.gif" />
      </th>
      <td>{'Cemetery'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/quarry.gif" />
      </th>
      <td>{'Quarry'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/park.gif" />
      </th>
      <td>{'Park'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/pitch.gif" />
      </th>
      <td>{'Pitch'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/recreation_track.gif" />
      </th>
      <td>{'Recreation track'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/apron.gif" />
      </th>
      <td>{'Airport apron'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/industrial.gif" />
      </th>
      <td>{'Hospital, university, school, residential area, industrial area, substation, plant'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/generator.gif" />
      </th>
      <td>{'Generator'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/hedge.gif" />
      </th>
      <td>{'Hedge'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/cliff.gif" />
      </th>
      <td>{'Cliff, embankment, cutting'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/powerline.gif" height="5" width="70" />
      </th>
      <td>{'Powerline'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/powertower.gif" height="5" width="70" />
      </th>
      <td>{'Powerline with powertower'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/powerpole.gif" height="5" width="70" />
      </th>
      <td>{'Powerline with powerpole'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/fence.gif" height="5" width="70" />
      </th>
      <td>{'Fence, wall'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/wall.gif" height="6" width="70" />
      </th>
      <td>{'City wall'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/ditch_trench.gif" height="3" width="70" />
      </th>
      <td>{'Trench'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/river.gif" width="70" />
      </th>
      <td>{'River'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/river_intermittent.gif" width="70" />
      </th>
      <td>{'Intermittent river'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/stream.gif" width="70" />
      </th>
      <td>{'Stream'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/stream_intermittent.gif" width="70" />
      </th>
      <td>{'Intermittent stream'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/stream_tunnel.gif" width="70" />
      </th>
      <td>{'Stream with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/canal.gif" width="70" />
      </th>
      <td>{'Canal'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/ditch.gif" width="70" />
      </th>
      <td>{'Ditch, drain'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/ditch_intermittent.gif" width="70" />
      </th>
      <td>{'Intermittent ditch, drain'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/canal_tunnel.gif" width="70" />
      </th>
      <td>{'Canal, ditch or drain with tunnel'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/railway_new_with_station.gif" width="70" />
      </th>
      <td>{'Train station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/railway_new_with_request_station.gif" width="70" />
      </th>
      <td>{'Train request station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/peak.gif" />
      </th>
      <td>{'Peak, volcano'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/saddle-15.gif" />
      </th>
      <td>{'Saddle'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/cave.gif" />
      </th>
      <td>{'Cave entrance'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/spring_z15.gif" />
      </th>
      <td>{'Spring'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/sinkhole.gif" />
      </th>
      <td>{'Sinkhole'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/broadleaf_tree.gif" />
      </th>
      <td>{'Tree'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/church_z13.gif" />
      </th>
      <td>{'Church, chapel, place of worship'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/hospital.gif" />
      </th>
      <td>{'Hospital'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/hut_alpine.gif" />
      </th>
      <td>{'Mountain hut'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/hut_wilderness.gif" />
      </th>
      <td>{'Wilderness hut'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/campsite.gif" />
      </th>
      <td>{'Camping'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/shelter.gif" />
      </th>
      <td>{'Shelter'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/golf.gif" />
      </th>
      <td>{'Golf course'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/swimming.gif" />
      </th>
      <td>{'Swimming pool, swimming area'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/viewpoint.gif" />
      </th>
      <td>{'Viewpoint'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/castle_z13.gif" />
      </th>
      <td>{'Castle'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/monument.gif" />
      </th>
      <td>{'Monument, plaque'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/tumulus.gif" />
      </th>
      <td>{'Archaeological site'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/parking.gif" />
      </th>
      <td>{'Parking'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/bus_stop.gif" />
      </th>
      <td>{'Bus stop'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/station_subway.gif" />
      </th>
      <td>{'Subway station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/railway_crossing.gif" />
      </th>
      <td>{'Level crossing'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/communications_mast.gif" />
      </th>
      <td>{'Mast'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/communications_tower_z13.gif" />
      </th>
      <td>{'Communication tower'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/water_tower.gif" />
      </th>
      <td>{'Water tower'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/watermill.gif" />
      </th>
      <td>{'Watermill'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/lighthouse.gif" />
      </th>
      <td>{'Lighthouse'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/wayside_cross.gif" />
      </th>
      <td>{'Wayside cross'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/power_station.gif" />
      </th>
      <td>{'Power station'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/photovoltaic.gif" />
      </th>
      <td>{'Solar generator'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/wind_turbine.gif" />
      </th>
      <td>{'Wind generator'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/mineshaft.gif" />
      </th>
      <td>{'Mineshaft, adit'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/bunker.gif" />
      </th>
      <td>{'Bunker'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Bike trails'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/bike_trail.gif" width="70" />
      </th>
      <td>{'Cycleway'}</td>
    </tr>

    <tr>
      <td colSpan={2}>
        <h3>{'Hike waymarked'}</h3>
      </td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/hike_red.gif" width="70" />
      </th>
      <td>{'Red hiking route'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/hike_blue.gif" width="70" />
      </th>
      <td>{'Blue hiking route'}</td>
    </tr>
    <tr>
      <th>
        <img src="map/scenes/topo/images/legend/hike_green.gif" width="70" />
      </th>
      <td>{'Green hiking route'}</td>
    </tr>
  </tbody>
)
